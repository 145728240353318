import "../styles/App.css";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useAtom } from 'jotai';
import { docuStageAtom, formStageView, headerAtom, headerStageAtom } from "../App";
import machine from "../assets/machine.png";
import completed from "../assets/completed.png";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import { useEffect, useState } from "react";
import { selectedOfferAtom } from "../components/Pages/Layout/offercard";
import Config from "../config"
import LoadingButton from "../components/Pages/Layout/loadingbutton";
import EquipmentCardGrey from "../components/Pages/Layout/equipmentCardGrey";
import EquipmentCard from "../components/Pages/Layout/equipmentCard";

function OfferSelected(props: any) {

    const params = useParams();
    const navigate = useNavigate();
    const data: any = useLoaderData();
    const offerData = data.data
    const offeredEquipments = offerData.Equipments.items;

    const [formHeader, setFormHeader] = useAtom(headerAtom)  
    const [progressHeader, setProgressHeader] = useAtom(headerStageAtom)   
    const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom)   
    const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom)    
    const [DocProgress, setDocProgress] = useAtom(DocProgressAtom)  
    const [formOfferView, setFormOfferView] = useAtom(formStageView);
    const [selOffer] = useAtom(selectedOfferAtom);
    const [isLoading, setIsLoading] = useState(false);

    const selectedOffer: any = 
        { 
            key: selOffer,
            monthlyPayment: offerData["Monthly Payment #" + selOffer],
            financedAmount: offerData["Total Financed Amount #" + selOffer],
            term: offerData["Assigned Term #" + selOffer],
            downPayment: offerData["Downpayment #" + selOffer],
            interestRate: offerData["Yield - Interest Rate #" + selOffer],
        };

    useEffect(() => {
        setFormHeader('Offer selected! Congrats!');
        setProgressHeader(false);
        setformDocuBar(true);
        setFormButtonShow(``);
        setDocProgress("step3");
        setFormOfferView(true);
        }, []);

    
    const navigateHandler = async () => {
            console.log("hash", params)
            setIsLoading(true)
            const payload = {
              offerId: offerData["Offer ID"],
              chosen: selOffer,
            };
            try {
                await fetch(`${Config.get("API_URL")}/api/loan/selected-offer`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Basic d2Vic2l0ZTpmb3Jt",
                  },
                  body: JSON.stringify(payload),
                }
              );
            } catch (error: any) {
              console.log("saveChosenOffer->Error: ", error.message);
              return false;
            }
            navigate(`/${params.hash}/offer-done`)
            return true;
    }

    return (
        <>  
            <BackButton lastPage={"offers"}/>
            <div className="font-normal text-base text-[#525351]">Your last step will be uploading documents for your finance contract.</div>
            <div className="flex flex-col">
                <label className="text-[#171718] font-semibold pl-0 pb-4 mt-10">Selected equipment</label>
                {offeredEquipments && offeredEquipments.map((eq:any) => (
                  <EquipmentCard
                    url={eq.avatar}
                    brand={eq.brand}
                    name={eq.name}
                  />
                ))}
{/*                 <div className="grid flex-row w-full grid-cols-[1fr_3fr_0.5fr] border items-center border-neutral-300 rounded flex-none order-1 self-stretch grow-0 border-solid p-6 mobile-eq-container">
                    <img src={machine} className="max-h-[4rem] pr-5 rounded-sm" alt="selected machine"/>
                    <div className="">
                        <div className="text-[#727373] font-normal text-[0.875_rem] leading-[21px];">Selected Brand</div>
                        <div className="text-[#171718] font-semibold pl-0">Selected Machine</div>
                        <div className="text-[#171718] font-semibold pl-0">$345,800 (excl. taxes)</div>
                    </div>
                    <img src={completed}  className="lettering max-w-[3rem] rounded-sm" alt="completed icon"/>
                </div> */}
            </div>
            <div className="flex flex-col mb-20">
                <label className="text-[#171718] font-semibold pl-0 pb-4 mt-10">Selected offer</label>
                <div className="flex flex-col w-full grid-cols-[1fr_3fr_0.5fr] border border-neutral-300 rounded flex-none order-1 self-stretch grow-0 border-solid p-6">
                    <div>
                        <div className="text-[#727373] font-normal text-[0.875_rem] leading-[21px];">Monthly payment</div>
                        <div className="text-[#171718] font-semibold text-lg leading-7 pl-0 pb-4">${selectedOffer.monthlyPayment} /mo</div>
                    </div>
                    <div className="bg-[#F6F5F5] rounded p-4">
                        <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
                            <div className="text-[#727373]">Financed amount</div>
                            <div className="text-[#171718] font-semibold">${selectedOffer.financedAmount}</div>
                        </div>
                        <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
                            <div className="text-[#727373]">Term</div>
                            <div className="text-[#171718] font-semibold">{selectedOffer.term}</div>
                        </div>
                        <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
                            <div className="text-[#727373]">Downpayment</div>
                            <div className="text-[#171718] font-semibold">${selectedOffer.downPayment}</div>
                        </div>
                        <div className="flex flex-row justify-between w-full mobile-loan-container">
                            <div className="text-[#727373]">Interest Rate</div>
                            <div className="text-[#171718] font-semibold">{selectedOffer.interestRate}</div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? 
                (<LoadingButton />)
                :
                <button onClick={navigateHandler} className="btn btn-primary">Select this Offer</button>
            }
        </>
    )
}

export default OfferSelected;