import "../styles/App.css";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useState } from "react";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import LoadingButton from "../components/Pages/Layout/loadingbutton";
import Config from "../config";

function AuthSigner(props: any) {
  const methods = useForm();
  const params = useParams();
  const data: any = useLoaderData();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const saveApplicationStep = async (values: any) => {
      const payload = {
        Status: values.buttonValue ? ["AUTH_SIGNER"] : ["INTERRUPTED"],
        token: params.hash,
        authSigner: values.buttonValue,
        currentFunnelPath: values.buttonValue ? data.currentStep = 4 : data.currentStep = 3
        /*   data.currentStep <= 3 ? data.currentStep + 1 : data.currentStep, */
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);
  };

  const navigateHandler = () => {
    setValue("buttonValue", true);
    navigate(`/${params.hash}/authorized-signer-information`);
  };

  const offRampHandler = () => {
    setValue("buttonValue", false);
    navigate(`/${params.hash}/in-review`);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);
  setFormHeader("Are you an authorized signer of the business?");
  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);
  setProgressHeader(true);
  const [formProgress, setFormProgress] = useAtom(progressAtom);
  setFormProgress(`w-3/5`);
  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);
  setFormProgressText("60%");
  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);
  setFormButtonShow(``);
  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);
  setformDocuBar(false);
  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);
  setFormNarrow(true);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { buttonValue: false },
  });

  return (
    <>
      <BackButton lastPage={"finance-information"} lastPath={2}/>
      <FormProvider {...methods}>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          {isLoading ? (
            <LoadingButton />
          ) : (
            <button
              {...register("buttonValue")}
              // Try to set an onClick={onSubmit} and set the value there. Try setValue inside
              type="submit"
              onClick={navigateHandler}
              className="btn btn-primary"
            >
              Yes
            </button>
          )}
          <button
            {...register("buttonValue")}
            // Try to set an onClick={onSubmit} and set the value there. Try setValue inside
            type="submit"
            onClick={offRampHandler}
            className="btn btn-primary"
          >
            No
          </button>
        </form>
      </FormProvider>
    </>
  );
}

export default AuthSigner;
