import "../styles/App.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom } from 'jotai';
import { docuStageAtom, headerAtom, headerStageAtom } from "../App";
import check from "../assets/check.svg";
import machine from "../assets/machine.png";
import completed from "../assets/completed.png";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";


function FinalThankYou(props: any) {

    const params = useParams();
    const navigate = useNavigate();

    const onSubmitHandler = async (values: any) => {
        const saveApplicationStep = (values: any) => {
            return new Promise((resolve) => resolve(true));
        };
        const saved = await saveApplicationStep(values);
        navigate(`/${params.hash}/`)    
    };

    const [formHeader, setFormHeader] = useAtom(headerAtom)
        setFormHeader('Your contract is signed! Thank you for trusting us with your business.')
    const [progressHeader, setProgressHeader] = useAtom(headerStageAtom)
        setProgressHeader(false)
    const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom)
        setformDocuBar(true)
    const [DocProgress, setDocProgress] = useAtom(DocProgressAtom)
        setDocProgress("step4")
    
    const selectedOffer: any = 
        { 
            key: 1,
            monthlyPayment: "3800",
            financedAmount: "210000",
            term: "76 months",
            downPayment: "100000",
            interestRate: "11.5%"
        }

    return (
        <>
            <img className="w-40 p-10 justify-self-center self-center bg-[#EBFBEE] rounded-full border-solid border-[#40C057]" src={check} alt="checkmark"></img>
            <div className="font-normal text-base leading-6 text-[#525351] py-8">We’ll send you a signed contract back by email shortly, and one of our sales representative will reach ot to you soon to guide you through the next step of getting your downpayment. As soon as we have your downpayment your equipment will be shipped to your equipment storage location you provided. Have questions? Call us at <u className="text-[#228BE6]" >+1 800 329 3375</u></div>
            <div className="flex flex-col">
                {/* <button className="btn-primary my-4" type="submit">Log in to your bank account (faster)</button> */}
            </div>
                        <div className="flex flex-col">
                <label className="text-[#171718] font-semibold pl-0 pb-4">Financed Equipment</label>
                <div className="grid flex-row w-full grid-cols-[1fr_3fr_0.5fr] border items-center border-neutral-300 rounded flex-none order-1 self-stretch grow-0 border-solid p-6 mobile-eq-container">
                    <img src={machine} className="max-h-[4rem] pr-5 rounded-sm" alt="selected machine"/>
                    <div className="">
                        <div className="text-[#727373] font-normal text-[0.875_rem] leading-[21px];">Selected Brand</div>
                        <div className="text-[#171718] font-semibold pl-0">Selected Machine</div>
                        <div className="text-[#171718] font-semibold pl-0">$345,800 (excl. taxes)</div>
                    </div>
                    <img src={completed}  className="lettering max-w-[3rem] rounded-sm" alt="completed icon"/>
                </div>
            </div>
                        <div className="flex flex-col">
                <label className="text-[#171718] font-semibold pl-0 pb-4">Finance Details</label>
                <div className="flex flex-col w-full grid-cols-[1fr_3fr_0.5fr] border border-neutral-300 rounded flex-none order-1 self-stretch grow-0 border-solid p-6">
                    <div>
                        <div className="text-[#727373] font-normal text-[0.875_rem] leading-[21px];">Monthly payment</div>
                        <div className="text-[#171718] font-semibold text-lg leading-7 pl-0 pb-4">${selectedOffer.monthlyPayment} /mo</div>
                    </div>
                    <div className="bg-[#F6F5F5] rounded p-4">
                        <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
                            <div className="text-[#727373]">Financed amount</div>
                            <div className="text-[#171718] font-semibold">${selectedOffer.financedAmount}</div>
                        </div>
                        <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
                            <div className="text-[#727373]">Term</div>
                            <div className="text-[#171718] font-semibold">{selectedOffer.term}</div>
                        </div>
                        <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
                            <div className="text-[#727373]">Downpayment</div>
                            <div className="text-[#171718] font-semibold">${selectedOffer.downPayment}</div>
                        </div>
                        <div className="flex flex-row justify-between w-full mobile-loan-container">
                            <div className="text-[#727373]">Interest Rate</div>
                            <div className="text-[#171718] font-semibold">{selectedOffer.interestRate}</div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="btn btn-primary mt-20">Leave This Page Securely</button>
        </>
    )
}

export default FinalThankYou;