import "../styles/App.css";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { Step2Data } from "../types";
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import { useEffect, useState } from "react";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import LoadingButton from "../components/Pages/Layout/loadingbutton";
import MenuBackButton from "../components/Pages/Layout/backbutton_history";
import Config from "../config";

function StorageLocation(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const data: any = useLoaderData();

  const formSchema: FieldAttributes[] = [
    {
      name: "businessAddress",
      label: "Storage Location Address",
      type: FieldType.ADDRESS,
      defaultValue: data.shippingAddress,
      validation: { required: true },
    },
  ];
  const onSubmitHandler = async (values: any) => {
    const saveApplicationStep = async (values: any) => {
      if (methods.formState.isValid) {
        setIsLoading(true);
      }
      const payload = {
        Status: ["CONFIRM_STORAGE"],
        token: params.hash,
        shippingAddress: values.businessAddress,
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);
    navigate(`/${params.hash}/documents`);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formProgress, setFormProgress] = useAtom(progressAtom);

  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);
  const [DocProgress, setDocProgress] = useAtom(DocProgressAtom);
  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  useEffect(() => {
    setFormHeader("Confirm equipment storage location");
    setProgressHeader(false);
    setFormProgress(`w-2/4`);
    setFormProgressText("50%");
    setFormButtonShow(``);
    setformDocuBar(true);
    setDocProgress("step4");
    setFormNarrow(true);
    console.log("beforeTrigger", methods.formState.isValid);
    methods.trigger();
    console.log("AfterTrigger", methods.formState.isValid);
  }, []);

  return (
    <>
      <MenuBackButton />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <div className="text-[#525351] mb-2">
            Need help? Call us at{" "}
            <u className="text-[#228BE6]">+1 800 329 3375</u>
          </div>
          {formSchema.map((field, index) => (
            <Field key={index} {...field} />
          ))}
          {isLoading ? (
            <LoadingButton />
          ) : (
            <button className="btn btn-primary mt-10" type="submit">
              Confirm location
            </button>
          )}
        </form>
      </FormProvider>
    </>
  );
}

export default StorageLocation;
