import "../styles/App.css";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import sign from "../assets/Icon-chevron-left.svg";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { useEffect, useState } from "react";
import { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import LoadingButton from "../components/Pages/Layout/loadingbutton";
import Config from "../config";

const CustomBackButton = () => {
  const params = useParams();
  const navigate = useNavigate();
  const data: any = useLoaderData();

  const onClickHandler = async () => {
    const saveApplicationStep = async () => {
      console.log("inApp", data.currentFunnel);
      const payload = {
        Status: ["SOLE_OWNER"],
        token: params.hash,
        funnelType:
          data.currentFunnel === 3
            ? 1
            : data.currentFunnel === 4
            ? 2
            : data.currentFunnel,
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep();
    navigate(`/${params.hash}/${"number-of-owners"}`);
  };

  const handleBrowserBack = () => {
    console.log("Browser back button pressed");
    onClickHandler();
  }

  useEffect(() => {
    window.addEventListener("popstate", handleBrowserBack);

    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, []);

  return (
    <>
      <div
        className={`relative left-[-4rem] bottom-[9rem] w-full max-w-screen-sm mx-auto lettering `}
      >
        <div
          onClick={onClickHandler}
          className="absolute flex left-[-7rem] w-28 items-center gap-x-3 gap-y-3 text-[#171718] font-semibold no-underline pl-3 pr-6 py-3 top-8 cursor-pointer"
        >
          <img src={sign} loading="lazy" alt="back icon"></img>
          <span>Back</span>
        </div>
      </div>
    </>
  );
};

function MultipleOwnersInfo(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const data: any = useLoaderData();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const saveApplicationStep = async (values: any) => {
      const payload = {
        Status: values.buttonValue ? ["MULTI_OWNERS_2"] : ["OWNERS"],
        token: params.hash,
        ownerOf20: values.buttonValue,
        currentFunnelPath: 5,
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);
  };

  const navigateHandler = () => {
    setValue("buttonValue", true);
    navigate(`/${params.hash}/sole-owner-information`);
  };

  const offRampHandler = () => {
    setValue("buttonValue", false);
    navigate(`/${params.hash}/owner-invite`);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formProgress, setFormProgress] = useAtom(progressAtom);

  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { buttonValue: false },
  });

  useEffect(() => {
    setFormHeader("Great! Do you have 20% or greater business ownership?");
    setProgressHeader(true);
    setFormProgress(`w-[40%]`);
    setFormProgressText("40%");
    setFormButtonShow(``);
    setformDocuBar(false);
    setFormNarrow(true);
  }, []);

  return (
    <>
      <CustomBackButton />
      <FormProvider {...methods}>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          {isLoading ? (
            <LoadingButton />
          ) : (
            <button
              {...register("buttonValue")}
              type="submit"
              onClick={navigateHandler}
              className="btn btn-primary"
            >
              Yes
            </button>
          )}
          <button
            {...register("buttonValue")}
            type="submit"
            onClick={offRampHandler}
            className="btn btn-primary"
          >
            No
          </button>
            
        </form>
      </FormProvider>
    </>
  );
}

export default MultipleOwnersInfo;
