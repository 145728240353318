import "../styles/App.css";
import { useAtom } from 'jotai';
import { docuStageAtom, headerAtom, headerStageAtom, narrowPageAtom } from "../App";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import { useEffect } from "react";


function LeftPage(props: any) {

    
/*     useEffect(() => {
        window.history.pushState(null, window.location.pathname);
        window.addEventListener("popstate", onBackButtonEvent);
        return () => {
          window.removeEventListener("popstate", onBackButtonEvent);
        };
      }, []);

      const onBackButtonEvent = (e : any ) => {
        e.preventDefault();
        window.history.pushState(null, window.location.pathname);
      }; */
      
    const [formHeader, setFormHeader] = useAtom(headerAtom)
        setFormHeader('You have exited your application securely')
    const [progressHeader, setProgressHeader] = useAtom(headerStageAtom)
        setProgressHeader(false)
    const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom)
        setformDocuBar(false)
    const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom)
        setFormNarrow(true)
    const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);
        setFormButtonShow(`hideall`);


    return (
        <>
            <p className="font-normal text-base pb-6 leading-6 text-[#525351]">To come back and complete your finance application at a later time, you need to use the secure link in the email that we have sent you. Need help? Call us <u className="text-[#228BE6]" >+1 800 329 3375</u></p>  
            <a href="https://www.machinerypartner.com/"><button className="btn btn-primary my-4 w-full" type="button">Back to home page</button></a>
        </>
    )
}

export default LeftPage;