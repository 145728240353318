import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import {useNavigate, useParams } from "react-router-dom";

export const selectedOfferAtom = atom("")

function OfferCard(props : any ) {

    const navigate = useNavigate();
    const params = useParams();
    const [selOffer, setSelOffer] = useAtom(selectedOfferAtom);

    const navigateHandler = async (e:any) => {
        e.preventDefault()
        await setSelOffer(props.props.key)
        navigate(`/${params.hash}/offer-selected`)
    };

    useEffect(() => {
        setSelOffer("");
      }, []);
    

    return (
        <>
            <div className="flex flex-row bg-white p-6 rounded-lg mb-4 border border-neutral-200 border-solid footer-simpl">
                <div className="flex flex-col pr-4 justify-around order-1 mobile-offercard-spacing">
                    <div className="font-normal text-[#727373] text-base leading-6 text-center mobile-offercard-margin">Monthly Payment</div>
                    <div className="flex flex-row font-bold text-3xl leading-[120%] justify-center mobile-offercard-margin2">${Number(props.monthlyPayment).toLocaleString('en-US')}<div className="text-base self-end">/mo</div></div>
                    <button className="btn w-full btn-primary items-center max-h-12" onClick={navigateHandler} >View Details</button>
                </div>
                <div className="flex flex-col order-1 grow ">
                    <div className="bg-[#F6F5F5] w-full rounded p-4 order-1 grow">
                        <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
                            <div className="text-[#727373]">Term</div>
                            <div className="text-[#171718] font-semibold">{props.term} </div>
                        </div>
                        <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
                            <div className="text-[#727373]">Downpayment</div>
                            <div className="text-[#171718] font-semibold">${Number(props.downPayment).toLocaleString('en-US')}</div>
                        </div>
                        <div className="flex flex-row justify-between w-full pb-4 mobile-loan-container">
                            <div className="text-[#727373]">Interest Rate</div>
                            <div className="text-[#171718] font-semibold">{props.interestRate}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default OfferCard;