import "../styles/App.css";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { Step9Data } from "../types";
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { useEffect, useState } from "react";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import Config from "../config";

function AuthInfo(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const data: any = useLoaderData();
  const [saveState, setSaveState] = useState(true);

  const formSchema: FieldAttributes[] = [
    {
      name: "nameOfAuthSigner",
      label: "Name of Authorized Signer",
      type: FieldType.TEXT,
      defaultValue: data.nameOfAuthSigner,
      validation: saveState ? { required: false } : { required: true },
    },
    {
      name: "titleOfAuthSigner",
      label: "Your Business Title",
      type: FieldType.TEXT,
      defaultValue: data.titleOfAuthSigner,
      validation: saveState ? { required: false } : { required: true },
    },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (values: any) => {
    const saveApplicationStep = async (values: any) => {
      const payload = {
        Status: ["AUTH_INFO"],
        token: params.hash,
        nameOfAuthSigner: values.nameOfAuthSigner,
        titleOfAuthSigner: values.titleOfAuthSigner,
        currentFunnelPath: saveState === true ? data.currentStep = 4 : data.currentStep = 5
/*           saveState === true
            ? data.currentStep
            : data.currentStep <= 4
            ? data.currentStep + 1
            : data.currentStep, */
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);
  };

  const saveHandler = async (values: any) => {
    setSaveState(true);
    PopUpToggler();
  };

  const navigateHandler = async (values: any) => {
    setSaveState(false);
    if (methods.formState.isValid) {
      navigate(`/${params.hash}/sign`);
    }
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formProgress, setFormProgress] = useAtom(progressAtom);

  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    setSaveState(false);
    setFormHeader("Great! Now, let’s verify your information");
    setProgressHeader(true);
    setFormProgress(`w-9/12`);
    setFormProgressText("75%");
    setFormButtonShow(``);
    setformDocuBar(false);
    setFormNarrow(true);
    console.log("beforeTrigger", methods.formState.isValid);
    methods.trigger();
    console.log("AfterTrigger", methods.formState.isValid);
  }, []);

  return (
    <>
      <BackButton lastPage={"authorized-signer-question"} lastPath={3}/>
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmitHandler)}>
          {formSchema.map((field, index) => (
            <Field key={index} {...field} />
          ))}
          <ButtonContainer
            button1={"Save & Finish Later"}
            button2={"Next"}
            loading={isLoading ? true : false}
            navigate={navigateHandler}
            save={saveHandler}
          />
        </form>
      </FormProvider>
    </>
  );
}

export default AuthInfo;
