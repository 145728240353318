import "../styles/App.css";
import { useLoaderData, useNavigate, useParams  } from "react-router-dom";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  submitAtom,
} from "../App";
import check from "../assets/check.svg";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import { useEffect, useState } from "react";
import Config from "../config";
import SubmitContent from "../components/Pages/Layout/submit_content";


function ThankYou(props: any) {
  const params = useParams();
  const data: any = useLoaderData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const submitLogic = () => {
    if (data.currentFunnel === 0) {
      return 8;
    } else if (data.currentFunnel === 1) {
      return 9;
    } else if (data.currentFunnel === 2) {
      return 17;
    } else if (data.currentFunnel === 3) {
      return 11;
    } else if (data.currentFunnel === 4) {
      return 19;
    }
  }

  const submitHandler = async (values: any) => {
    setShowSubmitModal(true)
    setShowSubmitContent(true)
/*     const saveApplicationStep = async (values: any) => {
      setIsLoading(true);
      const payload = {
        Status: ["REVIEW"],
        token: params.hash,
        currentFunnelPath: submitLogic(),
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);
    navigate(`/${params.hash}/in-review`); */
  };

  const optimizeLogic = () => {
    if (data.currentFunnel === 0) {
      return 7;
    } else if (data.currentFunnel === 1) {
      return 8;
    } else if (data.currentFunnel === 2) {
      return 8;
    } else if (data.currentFunnel === 3) {
      return 10;
    } else if (data.currentFunnel === 4) {
      return 10;
    }
  }

  const navigateHandler = async (values: any) => {
    const saveApplicationStep = async (values: any) => {
      setIsLoading(true);
      const payload = {
        Status: ["REVIEW"],
        token: params.hash,
        currentFunnelPath: optimizeLogic(),
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);
    navigate(`/${params.hash}/optimize`);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);
  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);
  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);
  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);
  const [DocProgress, setDocProgress] = useAtom(DocProgressAtom);
  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);
  const [showSubmitModal, setShowSubmitModal] = useAtom(submitAtom)
  const [showSubmitContent, setShowSubmitContent] = useState(false)

  
  const SubmitToggler = () => {
    setShowSubmitModal(!showSubmitModal);
    setShowSubmitContent(!showSubmitContent)
  };

  useEffect(() => {
    setFormHeader("Application signed successfully!");
    setProgressHeader(false);
    setFormButtonShow(`hideall`);
    setformDocuBar(true);
    setDocProgress("step1");
    setFormNarrow(false);
    setShowSubmitModal(false)
  }, []);

  return (
    <>
      {showSubmitContent && <SubmitContent toggle={SubmitToggler} submit={undefined} optimize={undefined} />}
      <img
        className="w-40 p-10 justify-self-center self-center bg-[#EBFBEE] rounded-full border-solid border-[#40C057]"
        src={check}
        alt="checkmark"
      ></img>
      <p className="font-normal text-base leading-6 my-10 text-[#525351]">
        To increase your likelihood of approval and lower your rate, we recommend optimizing your application before submitting it for review.
      </p>
      <div className="flex flex-row gap-4 mt-6 btn-container-mob">
        <button
          className="btn btn-secondary px-2"
          onClick={submitHandler}
          type="submit"
        >
          Submit without Optimization
        </button>
        <button
          className="btn btn-primary"
          onClick={navigateHandler}
          type="submit"
        >
          Optimize (recommended)
        </button>
      </div>
    </>
  );
}

export default ThankYou;
