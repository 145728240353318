import React, { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import { AddressFieldFieldAttributes } from "../../models/FieldAttributes";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

const AddressField: React.FC<AddressFieldFieldAttributes> = ({
  label,
  name,
  validation,
  defaultValue,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const form = useFormContext();

  const [value, setValue] = useState<any>(defaultValue);

  useEffect(() => {
    form.setValue(name, value);
  }, []);

  const handleSelect = async (e: any) => {
    const addressComponents = await geocodeByPlaceId(e.value.place_id);
    const postalCodeObj = addressComponents[0].address_components.find((component: any) => component.types.includes("postal_code"));
    const postalCode = postalCodeObj ? postalCodeObj.long_name : "";

    const fullAddress = `${e.label} ${postalCode}`;
    setValue(fullAddress);
    form.setValue(name, fullAddress);
  };

  return (
    <div className="relative w-full mb-2">
      <label
        htmlFor={name}
        {...(validation.required ? { "data-required": "true" } : {})}
        className=" text-[#171718] font-semibold pl-0"
      >
        {label}
      </label>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyAZYixMnqv_oF4S6Z-EBopL5S-FUeEYfoo"
        apiOptions={{ language: "en", region: "en" }}
        {...register(name, validation)}
        selectProps={{
          onChange: handleSelect,
          defaultInputValue: defaultValue,
          placeholder: "Type...",
          styles: {
            defaultInputValue: (provided: any) => ({
              ...provided,
              paddingBottom: "2rem",
              focus: "none",
            }),
            input: (provided: any) => ({
              ...provided,
              paddingBottom: "0rem",
              focus: "none",
              marginTop: "0rem",
            }),
            control: (provided: any) => ({
              ...provided,
              maxHeight: "3rem",
              marginTop: "0.5rem",
              paddingTop: "0rem",
              paddingBottom: "0.5rem",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              borderRadius: "0.25rem",
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: "#d4d4d4",
              boxShadow: "inset 1px 1px 3px 0 rgba(0, 0, 0, 0.06)",
            }),
            option: (provided: any) => ({
              ...provided,
              color: "",
            }),
            singleValue: (provided: any) => ({
              ...provided,
              color: "",
              paddingBottom: "0rem",
            }),
          },
        }}
      />
      {errors[name] ? (
        <>
          {errors[name]?.type === "required" ? (
            <>
              <span className="absolute left-0 top-fullmin-h-[0.25rem] text-[#FA5252] text-sm">
                Field "{label}" is required.
              </span>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AddressField;
