import "../styles/App.css";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { Step8Data } from "../types";
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import lock from "../assets/lock.svg";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { useEffect, useState } from "react";
import { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import MenuBackButton from "../components/Pages/Layout/backbutton_history";
import Config from "../config";

function Context(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const data:any = useLoaderData();
  const taxData = data.taxID

  const formSchema: FieldAttributes[] = [
    {
      name: "businessContext",
      label: "What does your business do?",
      placeholder: "E.g.: We have been involved in commercial construction and excavation in the local area for 10 years, specializing in demolishing houses and transporting excess materials back to our lot.",
      type: FieldType.TEXTAREA,
      defaultValue: data.businessContext,
      validation: { required: false },
    },
    {
      name: "businessReason",
      label: "What is the reason for your purchase?",
      placeholder: "E.g.: After building a supply of excess materials we are looking to process that in to usable/sellable material to make money.",
      type: FieldType.TEXTAREA,
      defaultValue: data.businessReason,
      validation: { required: false },
    },
  ];

  const formSchema2: FieldAttributes[] = [
    {
      name: "taxID",
      label: "Federal Tax ID (EIN)",
      type: FieldType.EIN,
      defaultValue: data.taxID,
      validation: { required: false },
    },
  ];


  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (values: any) => {
    const saveApplicationStep = async (values: any) => {
      const payload = {
        Status: ["ADDITIONAL_INFO"],
        token: params.hash,
        businessActivity: values.businessContext,
        purchaseReason: values.businessReason,
        ein: values.taxID
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);
    console.log(values);
  };

  const saveHandler = async (values: any) => {
    PopUpToggler();
  };

  const navigateHandler = async (values: any) => {
    const saveApplicationStep = async (values: any) => {
      const payload = {
        Status: ["REVIEW"],
        token: params.hash,
        businessActivity: values.businessContext,
        purchaseReason: values.businessReason,
        ein: values.taxID
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    await saveApplicationStep(values);
    navigate(`/${params.hash}/optimize`);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formProgress, setFormProgress] = useAtom(progressAtom);

  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    setFormHeader("Tell us about your business");
    setProgressHeader(true);
    setFormProgress(`w-9/12`);
    setFormProgressText("75%");
    setFormButtonShow(``);
    setformDocuBar(false);
    setFormNarrow(true);
    methods.trigger();
  }, []);

  return (
    <>
      <MenuBackButton />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <p className="font-normal text-base leading-6 text-[#525351]">
            Need help? Call us{" "}
            <a href="tel:18003293375">
              <u className="text-[#228BE6]">+1 800 329 3375</u>
            </a>
          </p>
          <div className="form">
            {taxData ? (null) : (
              formSchema2.map((field, index) => (
                <Field key={index} {...field} />
              )))}
            {formSchema.map((field, index) => (
              <Field key={index} {...field} />
            ))}
          </div>

          <ButtonContainer
            button1={"Save & Finish Later"}
            button2={"Save & Continue"}
            loading={isLoading ? true : false}
            navigate={navigateHandler}
            save={saveHandler}
          />
        </form>
      </FormProvider>
    </>
  );
}

export default Context;
