import "../styles/App.css";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
} from "../App";
import { Uploader } from "uploader";
import { UploadDropzone } from "react-uploader";
import { useForm } from "react-hook-form";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import { useEffect, useState } from "react";
import { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import LoadingButton from "../components/Pages/Layout/loadingbutton";
import Config from "../config";

// Initialize once (at the start of your app).
const uploader = Uploader({ apiKey: "public_FW25axZ42W9SnxbXHqBDp6LLrqDT" }); // Replace "free" with your API key.
const uploaderOptions = {
  multi: true,
  showFinishButton: false,
  editor: { images: { crop: false } },
  styles: {
    colors: {
      primary: "#228BE6",
      active: "#FFBA3F",
    },
    fontSizes: {
      base: 16,
    },
  },
};

function DocReUpload(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const data: any = useLoaderData();
  const [isLoading, setIsLoading] = useState(false);

  let [allDocs, setAllDocs] = useState<any[]>([]);
  let oldDocs = data.documents;

  interface Payload {
    Status: string[];
    token: string | undefined;
    documents?: any[]; // Define documents as an optional property
  }

  const saveApplicationStep = async (file: any) => {
    setIsLoading(true);
    let newDocs: any = [];
    if (oldDocs !== undefined) {
      newDocs = [...oldDocs, ...allDocs];
    } else {
      newDocs = [...allDocs];
    }
    const payload: Payload = {
      Status: ["ADDITIONAL_DOCS"],
      token: params.hash,
    };
    if (allDocs.length > 0) {
      payload.documents = newDocs;
    }
    try {
      await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic d2Vic2l0ZTpmb3Jt",
        },
        body: JSON.stringify(payload),
      });
    } catch (e) {
      console.error(e);
      return false;
    }

    return true;
  };

  const navigateHandler = async (values: any) => {
    await saveApplicationStep(values);
    navigate(`/${params.hash}/in-review`);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [DocProgress, setDocProgress] = useAtom(DocProgressAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  useEffect(() => {
    setFormHeader("Upload Documents");
    setProgressHeader(false);
    setFormButtonShow(`hideall`);
    setformDocuBar(true);
    setDocProgress("step2");
    setFormNarrow(false);
  }, []);

  return (
    <>
      <div className="font-normal text-base text-[#525351]">
        Need help? Call us <u className="text-[#228BE6]">+1 800 329 3375</u>
      </div>
      <div className="text-[#171718] font-semibold pl-0 pb-0">Upload Files</div>
      <UploadDropzone
        uploader={uploader}
        options={uploaderOptions}
        onUpdate={(files) => {
          const temp = files.map((file) => {
            return {
              url: file.originalFile.fileUrl,
              filename: file.originalFile.originalFileName,
            };
          });
          setAllDocs([...temp]);
        }}
        onComplete={(files) => {
          const temp = files.map((file) => {
            return {
              url: file.originalFile.fileUrl,
              filename: file.originalFile.originalFileName,
            };
          });
          /* setAllDocs([temp]) */
        }}
        width="auto"
        height="260px"
      />
      {data.documents && data.documents.map((document : any, index : any) => (
        <div className="items-center gap-4 bg-[#F6F5F5] p-4 mx-4 text-base leading-6 text-[#525351]" key={index}>{document.filename}</div>
      ))}
      {isLoading ? (
        <LoadingButton />
      ) : (
        <button className="btn btn-primary mt-6" onClick={navigateHandler}>
          Submit Documents
        </button>
      )}
    </>
  );
}

export default DocReUpload;
