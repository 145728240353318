import { atom, useAtom } from "jotai";
import machine from "../assets/machine.png";
import OfferCard from "../components/Pages/Layout/offercard";
import { docuStageAtom, formStageView, headerStageAtom } from "../App";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import Config from "../config";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import EquipmentCardGrey from "../components/Pages/Layout/equipmentCardGrey";
import { useEffect } from "react";

function OfferSelection(props: any) {

  const params = useParams();
  const data: any = useLoaderData();
  const navigate = useNavigate();

  const offerData = data.data

  const offeredEquipments = offerData.Equipments.items;
  const totalPrice = offerData.Equipments.totalPrice.formated;

  const offersReceived: any = [
    {
      key: 1,
      monthlyPayment: offerData["Monthly Payment #1"],
      financedAmount: offerData["Total Financed Amount #1"],
      term: offerData["Assigned Term #1"],
      downPayment: offerData["Downpayment #1"],
      interestRate: offerData["Yield - Interest Rate #1"],
      navigate: `/${params.hash}/offer-selected`,
    },
    {
      key: 2,
      monthlyPayment: offerData["Monthly Payment #2"],
      financedAmount: offerData["Total Financed Amount #2"],
      term: offerData["Assigned Term #2"],
      downPayment: offerData["Downpayment #2"],
      interestRate: offerData["Yield - Interest Rate #2"],
      navigate: `/${params.hash}/offer-selected`,
    },
    {
      key: 3,
      monthlyPayment: offerData["Monthly Payment #3"],
      financedAmount: offerData["Total Financed Amount #3"],
      term: offerData["Assigned Term #3"],
      downPayment: offerData["Downpayment #3"],
      interestRate: offerData["Yield - Interest Rate #3"],
      navigate: `/${params.hash}/offer-selected`,
    },
  ];

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);
  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);
  const [formOfferView, setFormOfferView] = useAtom(formStageView);
  const [DocProgress, setDocProgress] = useAtom(DocProgressAtom);

  useEffect(() => {
    setProgressHeader(false);
    setformDocuBar(true);
    setFormOfferView(false);
    setDocProgress("step3");
  }, []);

  return (
    <>
      <div className="flex flex-row footer-simpl">
        <div className="max-w-[500px]">
          <div className="text-[#171718] font-bold text-4xl leading-[120%] pb-6 mobile-title">
            Select your preferred payment plan
          </div>
          <div className="flex flex-col order-1">
            <div className="flex flex-col w-full grid-cols-[1fr_3fr_0.5fr] border border-neutral-300 rounded flex-none order-1 self-stretch grow-0 border-solid p-6 mobile-eq-container">
              <div className="flex flex-col">
                {offeredEquipments && offeredEquipments.map((eq:any) => (
                  <EquipmentCardGrey
                    url={eq.avatar}
                    brand={eq.brand}
                    name={eq.name}
                  />
                ))}
              </div>
              <div className="h-px bg-neutral-300 m-2" />
              <div className="flex flex-row justify-between">
                <div className="text-[#727373] font-normal text-[0.875_rem] leading-[21px];">
                  Total Financed Amount
                </div>
                <div className="text-[#171718] font-semibold pl-0">
                {totalPrice}
                </div>
              </div>
            </div>
            <div className="order-2 mb-8 self-stretch grow-0 pt-6 font-normal text-base leading-6 text-[#525351]">
              We were able to get the three best offers for your chosen heavy
              equipment. Have questions? Call us at{" "}
              <u className="text-[#228BE6]"> +1 800 329 3375 </u>
            </div>
          </div>
        </div>
        <div className="w-full m-4 mobile-offercard">
          {offersReceived.map((offer: any) => (
            <OfferCard
              props={offer}
              key={offer.key}
              monthlyPayment={offer.monthlyPayment}
              term={offer.term}
              financedAmount={offer.financedAmount}
              downPayment={offer.downPayment}
              interestRate={offer.interestRate}
              navOffer={offer.navigate}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default OfferSelection;
