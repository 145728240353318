import "../../../styles/style.css";
import x from "../../../assets/x.svg"
import check from "../../../assets/check.svg"
import { useEffect } from "react";
 
const PopUp = ({ toggle}: {toggle: any}) => {

    const handleClick = () => {
        toggle()
    }

    useEffect(() => {
        window.history.pushState(null, "", window.location.pathname);
        window.addEventListener("popstate", onBackButtonEvent);
        return () => {
          window.removeEventListener("popstate", onBackButtonEvent);
        };
      }, []);

      
    const onBackButtonEvent = (e : any) => {
        e.preventDefault();
        window.history.pushState(null, "", window.location.pathname);
      };
    

    const leaveHandler = () => {
        
    
    } 

  return (
    <>  
    <div className="popup-modal">
    {/* {seePopUp && ( */}
        <div className="popup-content flex flex-col w-[600px] bg-white border border-neutral-300 shadow-[0px_20px_25px_rgba(0,0,0,0.1),0px_10px_10px_rgba(0,0,0,0.04)] p-6 rounded-lg border-solid popup-mob">
            <div className="flex flex-row w-full justify-between">
                <div className="flex flex-row">
                    <img className="p-1 mr-2 bg-[#EBFBEE] rounded-full" src={check} alt="check"/>
                    <div className="font-bold text-center text-2xl text-[#171718] ">Saved Successfully!</div>
                </div>
                <img className="cursor-pointer" onClick={handleClick} src={x} alt="escape"></img>
                </div>
            <div className="py-8 font-normal text-base leading-6 text-[#525351]">To come back and complete your finance application at a later time, you will need to use the secure link that we have sent you by email. Your application will remain active for you to access and update your application for the next 30 days. </div>
            <div className="flex flex-row justify-end popup-mob-but">
                <button onClick={handleClick} className="btn btn-secondary max-w-[170px] popup-mob-but-pr">Back to Editing</button>
                <button onClick={leaveHandler} className="btn btn-primary ml-4 max-w-[170px] popup-mob-but-pr"><a href="https://www.machinerypartner.com/">Leave Securely</a></button>
            </div>
        </div>
    {/* )} */}
    </div>
    </>
  );
}

export default PopUp;