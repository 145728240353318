import "../../../styles/style.css";
import LoadingButton from "./loadingbutton";

const ButtonContainer = (props: any) => {

  return (
    <>
      <div className="btn-container btn-container-mob">
        <button className="btn btn-secondary" type="submit" onClick={props.save} >{props.button1}</button>
        {props.loading ? (<LoadingButton />) : (<button className="btn btn-primary" type="submit" onClick={props.navigate} disabled={props.disabled}>{props.button2}</button>)} 
        {/* <button className="btn btn-primary" type="submit" onClick={props.navigate}>{props.button2}</button> */}
      </div>
    </>
  );
}

export default ButtonContainer;