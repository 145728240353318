import "../styles/App.css";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
} from "../App";
import docusign_big from "../assets/docusign_1.png";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import { useEffect, useState } from "react";
import LoadingButton from "../components/Pages/Layout/loadingbutton";
import Config from "../config";

function ThankYouOffRamp(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const data: any = useLoaderData();
  const [isLoading, setIsLoading] = useState(false);

  const navigateHandler = async (values: any) => {
    const saveApplicationStep = async (values: any) => {
      setIsLoading(true);
      window.location.href = "https://www.machinerypartner.com/";
      return true;
    };
    const saved = await saveApplicationStep(values);

    // navigate(`/${params.hash}/leave-application`)
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formProgress, setFormProgress] = useAtom(progressAtom);

  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  useEffect(() => {
    setFormHeader(
      "Check your email for a DocuSign email to sign and submit your finance application."
    );
    setProgressHeader(false);
    setFormProgress(`w-9/12`);
    setFormProgressText("70%");
    setFormButtonShow(``);
    setformDocuBar(false);
    setFormNarrow(true);
  }, []);

  return (
    <>
      <img
        className="w-[200px] justify-self-center self-center my-16"
        src={docusign_big}
        alt="checkmark"
      ></img>
      <div className="text-[#525351] mt-6 mb-10">
        Have questions? Call us at{" "}
        <u className="text-[#228BE6]">+1 800 329 3375</u>
      </div>
      {isLoading ? (
        <LoadingButton />
      ) : (
        <button
          className="btn btn-primary my-4 "
          type="submit"
          onClick={navigateHandler}
        >
          Leave This Page Securely
        </button>
      )}
    </>
  );
}

export default ThankYouOffRamp;
