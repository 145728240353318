import React from "react";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import InputField from "./Fields/InputField";
import SelectField from "./Fields/SelectField";
import CheckboxField from "./Fields/CheckboxField";
import SSNField from "./Fields/SSNField";
import EmailField from "./Fields/EmailField";
import CurrencyField from "./Fields/CurrencyField";
import DateField from "./Fields/DateField";
import EINField from "./Fields/EINField";
import AddressField from "./Fields/AddressField";
import TextAreaField from "./Fields/TextAreaField";

const Field: React.FC<FieldAttributes> = (props) => {
  switch (props.type) {
    case FieldType.TEXT:
      return <InputField {...props} />;
    case FieldType.SELECT:
      return <SelectField {...props} />;
    case FieldType.CHECKBOX:
      return <CheckboxField {...props} />;
    case FieldType.SSN:
      return <SSNField {...props} />;
    case FieldType.EMAIL:
      return <EmailField {...props} />;
    case FieldType.CURRENCY:
      return <CurrencyField {...props} />;
    case FieldType.DATE:
        return <DateField {...props} />;
    case FieldType.EIN:
        return <EINField {...props} />;
    case FieldType.ADDRESS:
        return <AddressField {...props} />;
    case FieldType.TEXTAREA:
        return <TextAreaField {...props} />;
    default:
      throw new Error("Invalid Field Type");
  }
};

export default Field;
