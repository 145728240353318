import sign from "../../../assets/Icon-chevron-left.svg";
import "../../../styles/style.css";

function MenuBackButton() {

  const onClickHandler = () => {
   window.history.go(-1)
  }

  return (
    <>
      <div className={`relative left-[-4rem] bottom-[7rem] w-full max-w-screen-sm mx-auto lettering `}>
        <div onClick={onClickHandler} className="absolute flex left-[-7rem] w-28 items-center gap-x-3 gap-y-3 text-[#171718] font-semibold no-underline pl-3 pr-6 py-3 top-8 cursor-pointer">
          <img src={sign} loading="lazy" alt="back icon"></img>
          <span>Back</span>
        </div>
      </div>
    </>
  );
}

export default MenuBackButton;
