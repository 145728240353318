import React, { useEffect } from "react";

import { useFormContext } from "react-hook-form";
import { SelectFieldAttributes } from "../../models/FieldAttributes";

const SelectField: React.FC<SelectFieldAttributes> = ({
  label,
  name,
  options,
  mode,
  defaultValue,
  validation
}) => {
  const { register, setValue, watch, formState: { errors } } = useFormContext();
  const selectedValue = watch(name);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, []);

  return (
    <div className="relative w-full mb-2">
      <label htmlFor={name} {...(validation.required ? { 'data-required': 'true'} : {})} className="leading-6 pb-2 text-[#171718] font-semibold pl-0">{label}</label>
      {mode === "SELECT" ? (
        <select {...register(name, validation)} id={name} className={` rounded w-full shadow-[inset_1px_1px_3px_0_rgba(0,0,0,0.06)] min-h-[3rem] mb-0 px-4 py-2 border-solid border-2 ${errors[name]?.type === "required" ? ('border-[#FA5252] bg-[#FFF5F5]') : ('border-neutral-300')}`}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <div {...register(name, validation)} >
          {options.map((option) => (
            <button
              type="reset"
              key={option.value}
              onClick={() => setValue(name, option.value)}
              value={option.value}
              className={
                selectedValue === option.value
                  ? "block ml-auto mt-5  w-full bg-slate-200 border-slate-900 border-2 text-black px-4 py-2 rounded-md transition scale-90"
                  : "block ml-auto mt-5  w-full bg-slate-200 text-black px-4 py-2 rounded-md transition scale-90"
              }
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
      {errors[name] ? (
        <>
          {errors[name]?.type === "required" ? (
            <>
              <span className="min-h-[0.25rem] text-[#FA5252] text-sm">
                Please select an option.
              </span>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SelectField;
