import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { headerAtom, headerStageAtom } from "../App";
import SignaturePad from "react-signature-pad-wrapper";
import React from "react";
import { Uploader } from "uploader";
import { tokenToString } from "typescript";
import LoadingButton from "../components/Pages/Layout/loadingbutton";
import toast from "react-hot-toast";

const upload = Uploader({ apiKey: "public_FW25axZ42W9SnxbXHqBDp6LLrqDT" });
const API_URL = "https://mp-loan-application.vercel.app"
//const API_URL = "http://localhost:3000"

export const ManualCreditEntry = () => {

    const methods = useForm();
    const [formHeader, setFormHeader] = useAtom(headerAtom);
    const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);
    const [additionalOwners, setAdditionalOwners] = useState(0);
    const signaturePadRef = React.useRef() as React.MutableRefObject<any>;
    const [noSign, setNoSign] = useState(false)
    const [loading, setLoading] = useState(false);
    const signature = "";

    const clearCanvas = () => {
      signaturePadRef.current.clear();
    };

    const formSchema: FieldAttributes[] = [
        {
          name: "email",
          label: "Email",
          type: FieldType.EMAIL,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "businessName",
          label: "Business Name",
          type: FieldType.TEXT,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "businessAddress",
          label: "Business Address",
          type: FieldType.ADDRESS,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "taxID",
          label: "Federal Tax ID (EIN)",
          type: FieldType.EIN,
          defaultValue: "",
          validation: false,
         },
         {
          name: "timeInBusiness",
          label: "Years in Business",
          type: FieldType.TEXT,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "monthlyRevenue",
          label: "Est. Monthly Revenue ",
          type: FieldType.CURRENCY,
          defaultValue: "",
          validation: { required: true },
        },
      ];

      const formSchema2: FieldAttributes[] = [
        {
          name: "financedAmount",
          label: "Financed Amount",
          type: FieldType.CURRENCY,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "equipment",
          label: "Financed Equipment",
          type: FieldType.TEXT,
          defaultValue: "",
          validation: false,
        },
        {
          name: "downpayment",
          label: "Est. Downpayment",
          type: FieldType.CURRENCY,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "term",
          label: "Est. Term",
          type: FieldType.SELECT,
          defaultValue: "60 months",
          mode: "SELECT",
          validation: { required: true },
          options: [
            { label: "60 months", value: "60 months" },
            { label: "24 months", value: "24 months" },
            { label: "36 months", value: "36 months" },
            { label: "48 months", value: "48 months" },
            { label: "72 months", value: "72 months" },
          ],
        },
    
        {
          name: "financePlan",
          label: "Finance Plan",
          type: FieldType.SELECT,
          defaultValue: "standard",
          mode: "SELECT",
          validation: { required: true },
          options: [
            { label: "Standard", value: "standard" },
            { label: "90 Day Delay", value: "90daydelay" },
          ],
        },
        {
          name: "taxExempt",
          label: "My business is tax exempt",
          type: FieldType.CHECKBOX,
          defaultValue: false,
          validation: { required: false },
        },
        {
          name: "equipmentStorageLocation",
          label: "Equipment Storage Location",
          type: FieldType.ADDRESS,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "equipmentUse",
          label: "Use case for equipment",
          type: FieldType.TEXT,
          defaultValue: "",
          validation: false,
        },
      ];

      const formSchema3: FieldAttributes[] = [
        {
          name: "fullName",
          label: "Full Name",
          type: FieldType.TEXT,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "phone",
          label: "PHONE",
          type: FieldType.TEXT,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "homeAddress",
          label: "Home Address",
          type: FieldType.ADDRESS,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "dateOfBirth",
          label: "Date of Birth",
          type: FieldType.DATE,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "businessOwnerShip",
          label: "Business Ownership %",
          type: FieldType.TEXT,
          defaultValue: "",
          validation: { required: true },
        },
        {
          name: "ssNumber",
          label: "Social Security Number",
          type: FieldType.SSN,
          defaultValue: "",
          validation: { required: true },
        },
      ];

      const formSchema4: FieldAttributes[] = [
        {
          name: "secOwner",
          label: "Second Owner Name",
          type: FieldType.TEXT,
          defaultValue: "",
          validation: false,
        },
        {
          name: "secEmail",
          label: "Second Owner Email",
          type: FieldType.EMAIL,
          defaultValue: "",
          validation: false,
        },
        {
          name: "secAddress",
          label: "Second Owner Address",
          type: FieldType.ADDRESS,
          defaultValue: "",
          validation: false,
        },
        {
          name: "secBirth",
          label: "Second Owner Date of Birth",
          type: FieldType.DATE,
          defaultValue: "",
          validation: false,
        },
        {
          name: "secOwnership",
          label: "Second Owner Business Ownership %",
          type: FieldType.TEXT,
          defaultValue: "",
          validation: false,
        },
        {
          name: "secSsn",
          label: "Second Owner Social Security Number",
          type: FieldType.SSN,
          defaultValue: "",
          validation: false,
        },
      ];

      const formSchema5: FieldAttributes[] = [
        {
          name: "thirdOwner",
          label: "Third Owner Name",
          type: FieldType.TEXT,
          defaultValue: "",
          validation: false,
        },
        {
          name: "thirdOwnerEmail",
          label: "Third Owner Email",
          type: FieldType.EMAIL,
          defaultValue: "",
          validation: false,
        },
        {
          name: "thirdAddress",
          label: "Third Owner Address",
          type: FieldType.ADDRESS,
          defaultValue: "",
          validation: false,
        },
        {
          name: "thirdBirth",
          label: "Third Owner Date of Birth",
          type: FieldType.DATE,
          defaultValue: "",
          validation: false,
        },
        {
          name: "thirdOwnership",
          label: "Third Owner Business Ownership %",
          type: FieldType.TEXT,
          defaultValue: "",
          validation: false,
        },
        {
          name: "thirdSsn",
          label: "Third Owner Social Security Number",
          type: FieldType.SSN,
          defaultValue: "",
          validation: false,
        },
      ];

      const handleAddOwner = () => {
        if (additionalOwners < 2) {
            setAdditionalOwners(prev => prev + 1);
        }
    };

    useEffect(() => {
      setFormHeader(
        "Credit Application"
      );
      setProgressHeader(false);
    }, []);

    const onSubmitHandler = async (values: any) => {
      if (methods.formState.isValid) {
        setLoading(true);
        // UPLOAD SIGNATURE
        const dataURL = signaturePadRef.current.toDataURL("image/png", 0.5);
        const filename = "signature.png";
      
        const img = await fetch(dataURL);
        const convertedImage = await img.blob();
        const file = new File([convertedImage], filename);
        const { fileUrl } = await upload.uploadFile(file);
      
        // START API
        try {

          const cleanedValue = values.businessOwnerShip.replace("%", "").trim();
          const parsedOwnership = parseFloat(cleanedValue);
          const taxExempt = values.taxExempt === "true" || values.taxExempt === true;

          const payload = {
            fullName: values.fullName,
            phone: values.phone,
            email: values.email,
            businessName: values.businessName,
            businessAddress: values.businessAddress,
            businessEin: values.taxID,
            yearsInBussiness: values.timeInBusiness,
            businessRevenue: values.monthlyRevenue,
            financedAmount: values.financedAmount,
            equipmentName: values.equipment,
            downpayment: values.downpayment,
            loanTerms: values.term,
            financePlan: values.financePlan,
            taxExempt: taxExempt,
            shippingAddress: values.equipmentStorageLocation,
            purchaseReason: values.equipmentUse,
            firstOwnerAddress: values.homeAddress,
            firstOwnerBirthDate: values.dateOfBirth,
            firstOwnerOwnership: parsedOwnership,
            firstOwnerSsn: values.ssNumber,
            secOwner: values.secOwner,
            secOwnerEmail: values.secEmail,
            secOwnerAddress: values.secAddress,
            secOwnerBirthDate: values.secBirth,
            secOwnership: values.secOwnership,
            secOwnerSsn: values.secSsn,
            thirdOwner: values.thirdOwner,
            thirdOwnerEmail: values.thirdOwnerEmail,
            thirdOwnerAddress: values.thirdAddress,
            thirdOwnerBirthDate: values.thirdBirth,
            thirdOwnerOwnership: values.thirdOwnership,
            thirdOwnerSsn: values.thirdSsn,
            firstOwner: values.fullName,
            firstEmail: values.email,
            signature: [
              {
                url: fileUrl,
                filename: filename,
              },
            ],
          };
          console.log("PAYLOAD", payload)

          await fetch(`${API_URL}/api/loan/start-manual`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic d2Vic2l0ZTpmb3Jt",
            },
            body: JSON.stringify(payload),
          });
        } catch (e) {
          toast.error("Submission error.")
          setLoading(false);
          console.error(e);
          return false;
        }
        setLoading(false);
        toast.success('Application submitted!')
        return true;
      };
    }

    return (
        <>
          <div>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
                <div className="flex flex-row items-center mb-2">
                  <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                    Business Information
                  </span>
                  <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
                </div>            
                {formSchema.map((field, index) => (
                    <Field key={index} {...field} />
                  ))}
                <div className="flex flex-row items-center mb-2 mt-4">
                  <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                    Finance Information
                  </span>
                  <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
                </div> 
                {formSchema2.map((field, index) => (
                    <Field key={index} {...field} />
                  ))}
                <div className="flex flex-row items-center mb-2 mt-4">
                  <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                    Personal Information
                  </span>
                  <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
                </div> 
                  {formSchema3.map((field, index) => (
                    <Field key={index} {...field} />
                  ))}
                {additionalOwners >= 1 && (
                  <>
                    <div className="flex flex-row items-center mb-2 mt-4">
                      <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                        Second Owner Information
                      </span>
                      <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
                    </div> 
                    {formSchema4.map((field, index) => (
                      <Field key={index} {...field} />
                    ))}
                  </>
                )}
                {additionalOwners >= 2 && (
                  <>
                    <div className="flex flex-row items-center mb-2 mt-4">
                      <span className="text-[#171718] font-medium text-sm tracking-wider uppercase">
                        Third Owner Information
                      </span>
                      <div className="flex-none ml-4 order-1 grow bg-neutral-300 h-0.5"></div>
                    </div> 
                    {formSchema5.map((field, index) => (
                      <Field key={index} {...field} />
                    ))}
                  </>
                )}
                {additionalOwners === 2 ? null : (<button className="btn btn-secondary mt-6" type="button" onClick={handleAddOwner} disabled={additionalOwners >= 2}>Add Owner</button>)}
                <div className="flex-none order-1 grow bg-neutral-300 h-0.5 mt-6 mb-6"></div>
                <div className="flex flex-row font-semibold text-base leading-6 text-[#171718] mt-6 mb-2">
                Signature<div className="pl-1 text-[#FA5252]">*</div>
              </div>
              <div className={` flex justify-start shadow-[inset_0px_2px_4px_rgba(0,0,0,0.06)] rounded border-2 border-solid ${noSign ? 'border-[#FA5252]' : 'border-neutral-300'}`}>
                <SignaturePad
                  ref={signaturePadRef}
                  height={200}
                  options={{
                    minWidth: 1,
                    maxWidth: 3,
                    penColor: "rgb(0, 0, 0",
                  }}
                />
              </div>
              <div className="flex flex-row mt-2 mb-6 space-between">
                <button
                  className="text-[#228BE6] border-[none] font-semibold w-[120px] "
                  onClick={clearCanvas}
                >
                Clear signature
                </button>
                {noSign ? 
                  <span className="left-0 min-h-[0.25rem] self-center text-[#FA5252] text-sm ml-4">
                  Signature is required.
                  </span> : <></>
                }
              </div>
              {loading ? <LoadingButton /> : <button className="btn btn-primary" type="submit" disabled={additionalOwners >= 2}>Submit Application</button>}
              </form>
              </FormProvider>
          </div>
        </>
    )

}
