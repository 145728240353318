import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import sign from "../../../assets/Icon-chevron-left.svg";
import "../../../styles/style.css";
import { atom, useAtom } from 'jotai';
import { useEffect } from "react";
import Config from "../../../config"

export const bbuttonAtom = atom(``)

function BackButton(props : any) {

  const location = useLocation();
  const params = useParams();
  const data : any = useLoaderData();
  const navigate = useNavigate();

  const onClickHandler = async () => {
    console.log("inBack", props.lastPath)
    const fetchData = async () => {
      const payload = {
        Status: data.Status,
        token: params.hash,
        currentFunnelPath: props.lastPath,
      }
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (error) {
        console.error(error);
      }
    };

    await fetchData();
    navigate(`/${params.hash}/${props.lastPage}`)
  }

  const handleBrowserBack = () => {
    console.log("Browser back button pressed");
    onClickHandler();
  }

  useEffect(() => {
    window.addEventListener("popstate", handleBrowserBack);

    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, []);

  const [formButtonShow] = useAtom(bbuttonAtom)

  return (
    <>
      <div className={`relative left-[-4rem] top-[-7rem] w-full max-w-screen-sm mx-auto lettering ${formButtonShow}`}>
        <div onClick={onClickHandler} className="absolute flex left-[-7rem] w-28 items-center gap-x-3 gap-y-3 text-[#171718] font-semibold no-underline pl-3 pr-6 py-3 top-8 cursor-pointer">
          <img src={sign} loading="lazy" alt="back icon"></img>
          <span>Back</span>
        </div>
      </div>
    </>
  );
}

export default BackButton;
