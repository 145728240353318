const SubmitModal = () => {

    return (
    <> 
        <div className="submit-modal">
        </div>
    </>
  );
}

export default SubmitModal;