import "../../../styles/style.css";
import logo from "../../../assets/mp-logo-symbol-black.svg";
import lettering from "../../../assets/mp-logo-lettering-black.svg";
import helplogo from "../../../assets/help-circle.svg";
import { atom, useAtom } from 'jotai';
import arrow from "../../../assets/Arrow.svg";
import { headerStageAtom } from "../../../App";

export const progressAtom = atom(``);
export const progressTextAtom = atom('');

function Header(props: any) {

  const [formProgress] = useAtom(progressAtom)
  const [formProgressText] = useAtom(progressTextAtom)
  const [progressHeader] = useAtom(headerStageAtom)

  const onClickHandler = () => {
    window.history.go(-1)
  }

  return (
    <>
      <div className="relative z-[2] bg-white py-4 shadow-[0px_1px_2px_rgba(0,0,0,0.06),0px_1px_3px_rgba(0,0,0,0.1)]">
        <div className="flex min-h-[1rem] justify-center px-[4.75rem] mobile-padding">
          <div className="flex w-full max-w-[80.5rem] auto-cols-[1fr] gap-x-4 gap-y-4 grid-cols-[1fr_3fr] flex-row justify-between mobile-container items-center;">
              <img
                src={arrow}
                className="rotate-90 hideit"
                onClick={onClickHandler}
                loading="lazy"
                alt="machinery partner logo"
              />
              <div className="flex flex-row">
                <img
                src={logo}
                className="h-8 ml-3"
                loading="lazy"
                alt="machinery partner logo"
                ></img>
                <img
                src={lettering}
                className="h-8 ml-3 lettering"
                loading="lazy"
                alt="machinery partner logo"
                ></img>
              </div>
            <div className="flex w-full justify-center items-center self-center">
              {progressHeader ? (
                <>
                  <div className="flex w-6/12 h-4 justify-start items-center self-auto flex-[0_auto] bg-[#f6f5f5] px-2 rounded-[999px]">
                    <div className={`h-2 self-center bg-[#ffba3f] pl-0 rounded-[99px] ${formProgress}`}></div>
                  </div>
                  <span className="block self-center text-[#727373] font-normal text-left no-underline pl-2">
                    {formProgressText}
                  </span></>
              ) : (<></>) }
            </div>
            <a
              href="https://www.machinerypartner.com/contact"
              target="_blank" 
              rel="noreferrer"
              className="flex justify-center items-center gap-x-2 gap-y-2 text-[#727373] font-semibold no-underline p-2"
            >
              <img
                src={helplogo}
                className="w-6 h-6;"
                loading="lazy"
                alt="help logo"
              ></img>
              <span className="text-[#727373] font-semibold lettering">
                Help
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
