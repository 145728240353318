import React from "react";

import { useFormContext } from "react-hook-form";
import { TextAreaFieldAttributes } from "../../models/FieldAttributes";

const TextAreaField: React.FC<TextAreaFieldAttributes> = ({
  label,
  name,
  type,
  defaultValue,
  placeholder,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  // if (errors[name]) console.log("InputField->errors: ", errors[name]);

  return (
    <div className="relative w-full mb-2">
      <label htmlFor={name} className="leading-6 pb-2 text-[#171718] font-semibold pl-0">
        {label}
      </label>
      <textarea
        className={` rounded w-full shadow-[inset_1px_1px_3px_0_rgba(0,0,0,0.06)] min-h-[3rem] mb-0 px-4 py-2 custom-placeholder border-solid border-2 ${errors[name]?.type === "required" ? ('border-[#FA5252] bg-[#FFF5F5]') : ('border-neutral-300')}`}
        defaultValue={defaultValue}
        {...register(name)}
        id={name}
        rows={4}
        placeholder={placeholder}
      />
      {errors[name] ? (
        <>
          {errors[name]?.type === "required" ? (
            <>
              <span className="absolute left-0 top-full min-h-[0.25rem] text-[#FA5252] text-sm">
                Field "{label}" is required.
              </span>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TextAreaField;