import "../styles/App.css";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import check from "../assets/check.svg";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import { useEffect, useState } from "react";
import Config from "../config";
import LoadingButton from "../components/Pages/Layout/loadingbutton";

function OfferReview(props: any) {
  const params = useParams();
  const data: any = useLoaderData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  
  const submitHandler = () => {
    setShowPopUp(!showPopUp);
  };

  const navigateHandler = async (values: any) => {
    const saveApplicationStep = async (values: any) => {
      setIsLoading(true);

      const payload = {
        Status: ["OFFER_REVIEW"],
        token: params.hash,
        currentFunnelPath: data.currentStep + 1,
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);

    navigate(`/${params.hash}/documents`);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [DocProgress, setDocProgress] = useAtom(DocProgressAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  useEffect(() => {
    setFormHeader(
      "Offer selected successfully! Now please provide us with additional information for your contract"
    );
    setProgressHeader(false);
    setFormButtonShow(`hideall`);
    setformDocuBar(true);
    setDocProgress("step4");
    setFormNarrow(false);
  }, []);

  return (
    <>
      <img
        className="w-40 p-10 justify-self-center self-center bg-[#EBFBEE] rounded-full border-solid border-[#40C057]"
        src={check}
        alt="checkmark"
      ></img>
      <div className="font-normal text-base text-[#525351] my-4">
        Need help? Call us{" "}
        <a href="tel:18003293375">
          <u className="text-[#228BE6]">+1 800 329 3375</u>
        </a>
      </div>
      <div className="flex flex-row gap-4 mt-6 btn-container-mob">
        <button
          className="btn btn-secondary"
          onClick={submitHandler}
          type="submit"
        >
          Save & Finish Later
        </button>
        {isLoading ? 
          (<LoadingButton />)
                :
          (<button
          className="btn btn-primary"
          onClick={navigateHandler}
          type="submit"
          >
          Continue
          </button>
          )}
      </div>
    </>
  );
}

export default OfferReview;
