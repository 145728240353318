import "../../../styles/style.css";

function Footer() {
  return (
    <>
      <div className="bg-[#f6f5f5] py-6">
        <div className="footer-padding block min-h-[1rem] justify-center px-[4.75rem]">
          <div className="w-full mx-auto;">
            <div className="flex flex-row justify-center items-center gap-x-4 gap-y-4 footer-simpl">
              <div className="grid justify-items-start items-center grid-flow-col auto-cols-auto gap-x-4 gap-y-4 grid-cols-[auto] grid-rows-[auto] justify-start footer-copy">
                <span className="text-[#727373] hover:opacity-70">
                  2023 Copyright © machinerypartner.com
                </span>
                <span className="text-neutral-300 footer-mobile">|</span>
              </div>
              <div className="flex gap-x-4 gap-y-4;">
                <a href="https://new-machinery-partner.webflow.io/privacy-policy" target="_blank" rel="noreferrer"><span className="text-[#727373] hover:opacity-70">Privacy</span></a>
                <span className="text-neutral-300">|</span>
                <a href="https://www.machinerypartner.com/terms-of-use" target="_blank" rel="noreferrer"><span className="text-[#727373] hover:opacity-70">Terms</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
