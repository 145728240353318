"use client";

import { useState, useEffect, useCallback } from "react";
import { Check } from "lucide-react";
import styles from "./loader.module.css";

const LoadingScreen = () => {
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  // Define the total duration you want for the loader (in milliseconds)
  const totalDuration = 28000; 

  // Define how often you want to update the progress (in milliseconds)
  const updateInterval = 80; // 50 milliseconds

  // Calculate the progress increment based on the total duration and update interval
  const progressIncrement = (updateInterval / totalDuration) * 100;

  const steps = [
    "Connecting to financial databases",
    "Generating financial reports",
    "Applying risk analysis",
    "Finalizing data synchronization",
  ];

  const items = [
    { name: "Connecting to financial databases", threshold: 0 },
    { name: "Generating financial reports", threshold: 40 },
    { name: "Applying risk analysis", threshold: 60 },
    { name: "Syncronizing data", threshold: 80 },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = Math.min(oldProgress + progressIncrement, 100);
        if (newProgress === 100) {
          clearInterval(timer);
          setIsLoaded(true);
        }
        return newProgress;
      });
    }, updateInterval);

    return () => clearInterval(timer);
  }, [progressIncrement]);

  useEffect(() => {
    setCurrentStep(Math.floor(progress / (100 / steps.length)));
  }, [progress, steps.length]);

  const isItemComplete = useCallback(
    (threshold: number) => {
      return progress >= threshold + 20; // Item is complete 20% after its threshold
    },
    [progress]
  );

  return (
    <div className={styles.wrapper}>
      {isLoaded && <h2 className={styles.title}>Complete</h2>}
      {!isLoaded && <h2 className={styles.title}>{steps[currentStep]}</h2>}
      <div className={styles.loaderbar}>
        <div className={styles.loader} style={{ width: `${progress}%` }}></div>
      </div>
      <div className={styles.list}>
        {items.map((item) => (
          <div
            key={item.name}
            className={`flex justify-between items-center transition-all duration-500 ${
              progress >= item.threshold ? "opacity-100" : "opacity-0"
            }`}
          >
            <span>{item.name}</span>
            <div
              className={`w-6 h-6 rounded-full flex items-center justify-center transition-all duration-300 ${
                isItemComplete(item.threshold)
                  ? `${styles.success}`
                  : `${styles.default}`
              }`}
            >
              <Check
                className={`text-white transition-all duration-300 ${
                  isItemComplete(item.threshold)
                    ? "opacity-100 scale-100"
                    : "opacity-0 scale-0"
                }`}
                size={16}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoadingScreen;