import "../../../styles/style.css";
import x from "../../../assets/x.svg"
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import Config from "../../../config"

const SubmitOptimize = ({ toggle, submit, optimize}: {toggle: any, submit: any, optimize: any}) => {

    const params = useParams();
    const navigate = useNavigate();
    const data: any = useLoaderData();

    const optimizeLogic = () => {
        if (data.currentFunnel === 0) {
            return 8;
          } else if (data.currentFunnel === 1) {
            return 9;
          } else if (data.currentFunnel === 2) {
            return 17;
          } else if (data.currentFunnel === 3) {
            return 11;
          } else if (data.currentFunnel === 4) {
            return 19;
          }
    }
      
    const handleClick = () => {
        toggle()
    }

    const handleSubmit = async (values: any) => {
        const saveApplicationStep = async (values: any) => {
            const payload = {
              Status: ["OPTIMIZE"],
              token: params.hash,
              currentFunnelPath: optimizeLogic(),
            };
            try {
              await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Basic d2Vic2l0ZTpmb3Jt",
                },
                body: JSON.stringify(payload),
              });
            } catch (e) {
              console.error(e);
              return false;
            }
      
            return true;
          };
          const saved = await saveApplicationStep(values);
          navigate(`/${params.hash}/in-review`);
    }

return (
    <> 
        {}
        <div className="submit-content-mob">
            <div className="flex flex-row justify-between">
                <div className="not-italic font-bold text-2xl leading-[120%] text-[#171718]">Submitting too soon?</div>
                <img className="cursor-pointer" onClick={handleClick} src={x} alt="escape"></img>
            </div>
            <div className="font-normal text-base leading-6 text-[#525351] mt-8 mb-12">
                You are about to submit your finance application without optimization. To increase your likelihood of approval, we highly recommend optimizing it before submitting it for review. Need help? Call us <a href="tel:18003293375"><u className="text-[#228BE6]">+1 800 329 3375</u></a>
            </div>
            <div className="flex flex-row gap-4 popup-mob-but">
                <button className="btn btn-secondary px-2" onClick={handleSubmit}>Submit</button>
                <button className="btn btn-primary" onClick={handleClick}>Continue Optimize</button>
            </div>
        </div>
    </>
  );
}

export default SubmitOptimize;