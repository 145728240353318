import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePostHogAnalytics } from './postHogIdentifyUser'

export default function PostHogPageView(): null {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const { trackEvent } = usePostHogAnalytics();

  useEffect(() => {
    const url = window.origin + location.pathname + location.search;
    setCurrentUrl(url);
  }, [location]);

  useEffect(() => {
    if (currentUrl) {
      trackEvent('$pageview', {
        $current_url: currentUrl,
      });
    }
  }, [currentUrl, trackEvent]);

  return null;
}