import "../styles/App.css";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import { FormProvider, useForm } from "react-hook-form";
import completed from "../assets/completed.png";
import { useEffect, useState } from "react";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import upload from "../assets/Upload.svg";
import user from "../assets/user.svg";
import navpoint from "../assets/map-pin.svg";
import Config from "../config";
import LoadingButton from "../components/Pages/Layout/loadingbutton";

function DocUploadForMazo(props: any) {
  const params = useParams();
  const methods = useForm();
  const navigate = useNavigate();
  const data: any = useLoaderData();
  const [isLoading, setIsLoading] = useState(false);

  let storageData = data.shippingAddress;
  let driverData = data.driversLicense;
  let checkData = data.blankCheck;

  const saveHandler = async (values: any) => {
    PopUpToggler();
  };

  const navigateHandler = async (values: any) => {
    const saveApplicationStep = async (values: any) => {
      setIsLoading(true);
      const payload = {
        Status: ["DOC_UPLOAD_OFFERS"],
        token: params.hash,
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);

    navigate(`/${params.hash}/in-review-final`);
  };

  const driverHandler = async (values: any) => {
    navigate(`/${params.hash}/documents/drivers-license`);
  };

  const checkHandler = async (values: any) => {
    navigate(`/${params.hash}/documents/check`);
  };

  const storageHandler = async (values: any) => {
    navigate(`/${params.hash}/documents/storage`);
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [DocProgress, setDocProgress] = useAtom(DocProgressAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    setFormHeader(
      "Before you sign your contract, you will need to upload the documents below"
    );
    setProgressHeader(false);
    setFormButtonShow(``);
    setformDocuBar(true);
    setDocProgress("step4");
    setFormNarrow(false);
  }, []);

  return (
    <>
      <div className="font-normal text-base text-[#525351]">
        Your last step will be uploading documents for your finance contract.
        Need help? Call us
        <a href="tel:18003293375">
          <u className="text-[#228BE6]"> +1-800-329-3375</u>
        </a>
      </div>
      <div className="grid grid-cols-[1fr_6fr_2fr] flex-row p-6 mb-6 border-neutral-300 rounded border-2 border-solid h-[88px] items-center justify-center mt-6 mobile-widget">
        {driverData ? (
          <img className="w-10 h-10 rounded" src={completed} />
        ) : (
          <img className="w-10 h-10 p-2 bg-[#F6F5F5] rounded" src={user} />
        )}
        <div className="font-semibold text-base leading-6 text-[#525351] mobile-form-pad">
          Upload Driver’s licence <span className="text-[#FA5252]">*</span>
        </div>
        <div
          onClick={driverHandler}
          className="w-[125px] h-10 flex flex-row justify-center items-center px-6 py-2 rounded border-2 border-solid border-[#171718] font-semibold text-base leading-6 cursor-pointer transition-[0.2s,] hover:bg-[#FFFAEB] hover:border-[#FF9900]"
        >
          Upload
        </div>
      </div>
      <div className="grid grid-cols-[1fr_6fr_2fr] flex-row p-6 mb-6 border-neutral-300 rounded border-2 border-solid h-[88px] items-center justify-center mobile-widget">
        {checkData ? (
          <img className="w-10 h-10 rounded" src={completed} />
        ) : (
          <img className="w-10 h-10 rounded" src={upload} />
        )}
        <div className="font-semibold text-base leading-6 text-[#525351] mobile-form-pad">
          Upload Blank / voided check {/* <span className="text-[#FA5252]">*</span> */}
        </div>
        <div
          onClick={checkHandler}
          className="w-[125px] h-10 flex flex-row justify-center items-center px-6 py-2 rounded border-2 border-solid border-[#171718] font-semibold text-base leading-6 cursor-pointer transition-[0.2s,] hover:bg-[#FFFAEB] hover:border-[#FF9900]"
        >
          Upload
        </div>
      </div>
      <div className="grid grid-cols-[1fr_6fr_2fr] flex-row p-6 mb-6 border-neutral-300 rounded border-2 border-solid h-[88px] items-center justify-center mb-8 mobile-widget">
        {storageData ? (
          <img className="w-10 h-10 rounded" src={completed} />
        ) : (
          <img className="w-10 h-10 rounded p-2 bg-[#F6F5F5]" src={navpoint} />
        )}
        <div className="font-semibold text-base leading-6 text-[#525351] mobile-form-pad">
          Confirm equipment storage location{" "}
          <span className="text-[#FA5252]">*</span>
        </div>
        <div
          onClick={storageHandler}
          className="w-[125px] h-10 flex flex-row justify-center items-center px-6 py-2 rounded border-2 border-solid border-[#171718] font-semibold text-base leading-6 cursor-pointer transition-[0.2s,] hover:bg-[#FFFAEB] hover:border-[#FF9900]"
        >
          Confirm
        </div>
      </div>
      <div className="btn-container btn-container-mob">
        <button className="btn btn-secondary" onClick={saveHandler}>Save & Finish Later</button>
        {isLoading ? (<LoadingButton />) 
        : 
        <button 
          className={`${(!driverData || !storageData) ? 'btn bg-neutral-200 text-neutral-400 cursor-not-allowed' : 'btn btn-primary' }`}
          onClick={navigateHandler} 
          type="submit" 
          disabled={ !driverData || !storageData}>
           Submit for Review
        </button>}
      </div>
{/*       <ButtonContainer
        button1={"Save & Finish Later"}
        button2={"Submit for Review"}
        navigate={navigateHandler}
        save={saveHandler}
      /> */}
    </>
  );
}

export default DocUploadForMazo;
