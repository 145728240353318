import React from "react";

import { useFormContext } from "react-hook-form";
import { DateFieldAttributes } from "../../models/FieldAttributes";

const DateField: React.FC<DateFieldAttributes> = ({
  label,
  name,
  type,
  defaultValue,
  validation,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  // if (errors[name]) console.log("DateField->errors: ", errors[name]);
  return (
    <div className="relative w-full mb-2">
      <label htmlFor={name} {...(validation.required ? { 'data-required': 'true'} : {})} className="leading-6 pb-2 text-[#171718] font-semibold pl-0">
        {label}
      </label>
      <input
        className={`max-h-12 rounded w-full shadow-[inset_1px_1px_3px_0_rgba(0,0,0,0.06)] min-h-[3rem] px-4 py-2 border-solid border-2 ${errors[name]?.type === "required" ? ('border-[#FA5252] bg-[#FFF5F5]') : ('border-neutral-300')}`}
        defaultValue={defaultValue}
        {...register(name, {
            ...validation, 
            pattern: /^\d{4}-\d{2}-\d{2}$/,
        })}
        id={name}
        type={type}
        placeholder="dd/mm/yyyy"
      />
      {errors[name] ? (
        <>
          {errors[name]?.type === "required" ? (
            <>
              <span className="absolute left-0 top-full min-h-[0.25rem] text-[#FA5252] text-sm">
                Field "{label}" is required.
              </span>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DateField;