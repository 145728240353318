import React from "react";
import { useFormContext } from "react-hook-form";
import { CheckboxFieldAttributes } from "../../models/FieldAttributes";

const CheckboxField: React.FC<CheckboxFieldAttributes> = ({
  label,
  name,
  type,
  defaultValue,
  validation,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
   /* if (errors[name]) console.log("CheckBox->errors: ", errors[name]); */

  return (
    <div className="relative w-full mb-2 flex flex-row items-center">
      <input
        className={`${errors[name]?.type === "required" ? ('border-[#FA5252]') : ('/')}`}
        defaultChecked={defaultValue === true ? true : false}
        defaultValue={defaultValue}
        {...register(name, validation)}
        id={name}
        type="checkbox"
        //checked={defaultValue ? true : false}
      />
      <label className="flex flex-row text-[#525351]" /* {...(validation.required ? { 'data-required': 'true'} : {})} */ htmlFor={name}>{label}</label>
      {name === "terms" && <a href="https://www.machinerypartner.com/terms-of-use" target="_blank" rel="noreferrer"><div className="font-normal text-base leading-6 text-[#228BE6]">&nbsp;Terms and Conditions <span className='text-[#FA5252]'>*</span></div></a>}
      {errors[name] ? (
        <>
          {errors[name]?.type === "required" ? (
            <>
              <div>
                <span className="absolute left-0 top-full text-sm text-[#FA5252]">
                  Required field
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CheckboxField;
