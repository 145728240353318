import "../styles/App.css";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { Step11Data } from "../types";
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/Field";
import FieldAttributes, { FieldType } from "../models/FieldAttributes";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";
import { useAtom } from "jotai";
import {
  docuStageAtom,
  headerAtom,
  headerStageAtom,
  narrowPageAtom,
  popUpAtom,
} from "../App";
import {
  progressAtom,
  progressTextAtom,
} from "../components/Pages/Layout/header";
import { useEffect, useState } from "react";
import BackButton, { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import Config from "../config";

function StartUpQuestion8(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const [saveState, setSaveState] = useState(true);
  const methods = useForm();
  const data: any = useLoaderData();

  const formSchema: FieldAttributes[] = [
    {
      name: "managementExp",
      label: "What kind of management experience do you have?",
      type: FieldType.TEXTAREA,
      placeholder: "",
      defaultValue: data.managementExp,
      validation: saveState ? { required: false } : { required: true },
    },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (values: any) => {
    const saveApplicationStep = async (values: any) => {
      if (!saveState && methods.formState.isValid) {
        setIsLoading(true);
      }
      const payload = {
        Status: ["STARTUP_QUESTION_END"],
        token: params.hash,
        managementExp: values.managementExp,
        currentFunnelPath: data.currentFunnel === 2 ? 
        (saveState === true ? 16 : 16) 
        :
        (saveState === true ? 18 : 18) 
      };
      try {
        await fetch(`${Config.get("API_URL")}/api/loan/persist`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic d2Vic2l0ZTpmb3Jt",
          },
          body: JSON.stringify(payload),
        });
      } catch (e) {
        console.error(e);
        return false;
      }

      return true;
    };
    const saved = await saveApplicationStep(values);
    console.log(values);
  };

  const saveHandler = async (values: any) => {
    setSaveState(true);
    PopUpToggler();
  };

  const navigateHandler = async (values: any) => {
    setSaveState(false);
    if (methods.formState.isValid) {
      navigate(`/${params.hash}/optimize`);
    }
  };

  const [formHeader, setFormHeader] = useAtom(headerAtom);

  const [progressHeader, setProgressHeader] = useAtom(headerStageAtom);

  const [formProgress, setFormProgress] = useAtom(progressAtom);

  const [formProgressText, setFormProgressText] = useAtom(progressTextAtom);

  const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);

  const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom);

  const [DocProgress, setDocProgress] = useAtom(DocProgressAtom);

  const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom);

  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    setSaveState(false);
    setFormHeader("Context Question 8/8");
    setProgressHeader(false);
    setFormProgress(`w-9/12`);
    setFormProgressText("75%");
    setFormButtonShow(``);
    setformDocuBar(true);
    setDocProgress("step2");
    setFormNarrow(false);
    console.log("beforeTrigger", methods.formState.isValid);
    methods.trigger();
    console.log("AfterTrigger", methods.formState.isValid);
  }, []);

  return (
    <>
      <BackButton lastPage={"context-question-07"} />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <p className="font-normal text-base leading-6 text-[#525351]">
            Need help? Call us{" "}
            <a href="tel:18003293375">
              <u className="text-[#228BE6]">+1 800 329 3375</u>
            </a>
          </p>
          <div className="form">
            {formSchema.map((field, index) => (
              <Field key={index} {...field} />
            ))}
          </div>

          <ButtonContainer
            button1={"Save & Finish Later"}
            button2={"Next"}
            loading={isLoading ? true : false}
            navigate={navigateHandler}
            save={saveHandler}
          />
        </form>
      </FormProvider>
    </>
  );
}

export default StartUpQuestion8;
