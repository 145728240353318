import {
  createBrowserRouter,
  Params,
  redirect,
  RouterProvider,

  useRouteError,
} from "react-router-dom";
import Header from "./components/Pages/Layout/header";
import Footer from "./components/Pages/Layout/footer";
import BusinessInfo from "./steps/businessInfo";

import { Step1Data } from "./types";
import docusign from "./assets/finance-logos.png";
import BackButton from "./components/Pages/Layout/backbutton";
import "./styles/style.css";
import LoanInfromation from "./steps/loanInformation";
import SoleOwner from "./steps/soleowner";
import Address from "./steps/homeaddress";
import Identification from "./steps/identification";
import SignApp from "./steps/sign";
import ThankYou from "./steps/thankyou";
import { atom, useAtom } from "jotai";
import DocumentsProgressBar from "./components/Pages/Layout/documentsbar";
import DocUpload from "./steps/docupload";
import DocFinished from "./steps/docfinished";
import DocReUpload from "./steps/docreupload";
import OfferSelected from "./offerselection/offers2";
import OfferSelection from "./offerselection/offers";
import DocUploadForMazo from "./offerselection/docuploadformazo";
import DocUploadForMazoThank from "./steps/docuploadformazothank";
import FinalThankYou from "./steps/finalthank";
import PopUp from "./components/Pages/Layout/popup";
import { useEffect, useState } from "react";
import ThankYouOffRamp from "./steps/thankyouofframp";
import LeftPage from "./steps/securelyleft";
import StorageLocation from "./offerselection/storagelocation";
import Optimize from "./steps/optimize";
import ScrollToTop from "./components/Pages/Layout/scrolltotop";
import Context from "./steps/context";
import AuthSigner from "./steps/authorizedSigner";
import AuthInfo from "./steps/authorizedInfo";
import MultipleOwners from "./steps/mutipleOwners";
import MultipleOwnersInfo from "./steps/multipleOwnersInfo";
import StartUpQuestion1 from "./steps/startupQuestion1";
import StartUpQuestion2 from "./steps/startUpQuestion2";
import StartUpQuestion3 from "./steps/startUpQuestion3";
import StartUpQuestion4 from "./steps/startUpQuestion4";
import StartUpQuestion5 from "./steps/startUpQuestion5";
import StartUpQuestion6 from "./steps/startUpQuestion6";
import StartUpQuestion7 from "./steps/startUpQuestion7";
import StartUpQuestion8 from "./steps/startUpQuestion8";
import CoOwnerInvite from "./steps/co-ownerinvite";
import OfferReview from "./offerselection/offerReview";
import DriverLUpload from "./offerselection/uploadDriverL";
import CheckUpload from "./offerselection/uploadCheck";
import Config from "./config";
import LoadingScreen from "./components/Pages/Layout/loadingScreen";
import SubmitModal from "./components/Pages/Layout/submit_modal";
import OwnerInvite from "./steps/owner-invite";
import { ManualCreditEntry } from "./manual-entry/manualEntry";
import posthog from "posthog-js";
import PostHogPageView from './utils/postHogPageView';
import { PostHogBehaviorTracker } from "./utils/postHogIdentifyUser";

const params = new URLSearchParams(document.location.search);

function theConverMethodYouCreated(response: any) {
  const fullName = response.data["Majority Owner Name"];
  const nameArray = fullName ? fullName.split(" ") : "";
  const secOwnerName = response.data["Second Owner Name"];
  const secNameArray = secOwnerName ? secOwnerName.split(" ") : "";
  const thirdOwnerName = response.data["Third Owner Name"];
  const thirdNameArray = thirdOwnerName ? thirdOwnerName.split(" ") : "";
  const fourthOwnerName = response.data["Fourth Owner Name"];
  const fourthNameArray = fourthOwnerName ? fourthOwnerName.split(" ") : "";
  const fifthOwnerName = response.data["Fifth Owner Name"];
  const fifthNameArray = fifthOwnerName ? fifthOwnerName.split(" ") : "";

  let newResponse = {
    businessName: response.data["Business Name"],
    taxID: response.data["Business EIN"],
    businessAddress: response.data["Business Street Address"],
    monthlyRevenue: response.data["Business Monthly Revenue"],
    downpayment: response.data["Max Downpayment"],
    equipmentLocation: response.data["Shipping Street Address"],
    term: response.data["Loan Terms"],
    taxExempt: response.data["Tax Exempt"],
    soleOwner: response.data["Single Owner"],
    firstName: nameArray[0],
    lastName: nameArray.length > 0 ? nameArray.slice(1).join(" ") : "",
    homeAddress: response.data["Majority Street Address"],
    dateOfBirth: response.data["Majority Owner Birth Date"],
    ssNumber: response.data["Majority Owner SSN"],
    loanAmount: response.data["Loan Amount"],
    documents: response.data["Documents"],
    equipments: response.data["Equipments"],
    businessContext: response.data["Business Main Activity"],
    businessReason: response.data["Reason for Purchase"],
    authSigner: response.data["Authorized Signer"],
    nameOfAuthSigner: response.data["Authorized Signer Name"],
    titleOfAuthSigner: response.data["Authorized Signer Title"],
    ownerOf20: response.data["Owner of more than 20%"],
    numberOfOwners: response.data["Number of Owners over 20%"],
    startBusiness: response.data["Startup Question 1"],
    equipmentReason: response.data["Startup Question 2"],
    otherEq: response.data["Startup Question 3"],
    financeDetails: response.data["Startup Question 4"],
    competition: response.data["Startup Question 5"],
    customerBase: response.data["Startup Question 6"],
    industryExp: response.data["Startup Question 7"],
    managementExp: response.data["Startup Question 8"],
    currentFunnel: response.data["Funnel Type"],
    currentStep: response.data["Funnel Stage"],
    secOwnerFirstName: secNameArray[0],
    secOwnerLastName:
      secNameArray.length > 0 ? secNameArray.slice(1).join(" ") : "",
    thirdOwnerFirstName: thirdNameArray[0],
    thirdOwnerLastName:
      thirdNameArray.length > 0 ? thirdNameArray.slice(1).join(" ") : "",
    fourthOwnerFirstName: fourthNameArray[0],
    fourthOwnerLastName:
      fourthNameArray.length > 0 ? fourthNameArray.slice(1).join(" ") : "",
    fifthOwnerFirstName: fifthNameArray[0],
    fifthOwnerLastName:
      fifthNameArray.length > 0 ? fifthNameArray.slice(1).join(" ") : "",
    thirdOwnerEmail: response.data["Third Owner Email"],
    fourthOwnerEmail: response.data["Fourth Owner Email"],
    fifthOwnerEmail: response.data["Fifth Owner Email"],
    secEmail: response.data["Second Owner Email"],
    majorityEmail: response.data["Majority Owner Email"],
    driversLicense: response.data["Drivers License"],
    blankCheck: response.data["Blank Check"],
    shippingAddress: response.data["Shipping Street Address"],
    signature: response.data["Signature"],
    Status: response.data["Status"],
    taxes: response.data["Sales Tax"],
    contactEmail: response.data["Email"],
    contactName: response.data["Contact Name"],
    majorityOwnership: response.data["Majority Owner Ownership"],
    businessState: response.data["Business State"],
    businessCity: response.data["Business City"],
    businessZip: response.data["Business Zip-Code"],
    product: response.data["Equipment"],
    timeInBusiness: response.data["Years in Business"],
    financePlan: response.data["Finance Plan"],
    secBirth: response.data["Second Owner Birth Date"],
    secOwnership: response.data["Second Owner Ownership"],
    secSsn: response.data["Second Owner SSN"],
    secOwner: response.data["Second Owner Name"],
    secAddress: response.data["Secondary Street Address"],
    thirdBirth: response.data["Third Owner Birth Date"],
    thirdOwner: response.data["Third Owner Name"],
    thirdOwnership: response.data["Third Owner Ownership"],
    thirdSsn: response.data["Third Owner SSN"],
    thirdAddress: response.data["Third Owner Address"],
    fourthBirth: response.data["Fourth Owner Birth Date"],
    fourthOwner: response.data["Fourth Owner Name"],
    fourthOwnership: response.data["Fourth Owner Ownership"],
    fourthSsn: response.data["Fourth Owner SSN"],
    fourthdAddress: response.data["Fourth Owner Address"],
    requirements: response.data["Requirements"],
    buyerScore: response.data["Buyer Score"],
  };
  return newResponse;
}

const getApplication = async (params: string): Promise< any > => {
  const payload = {
    token: params,

    // source: "CREDIT_APP",
  };
  try {
    const loadApplication = await fetch(
      `${Config.get("API_URL")}/api/loan/security`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic d2Vic2l0ZTpmb3Jt",
        },
        body: JSON.stringify(payload),
      }
    );
    const response = await loadApplication.json();
    console.log("getAppication: ", response);
    // return response
    return theConverMethodYouCreated(response);
  } catch (e: any) {
    console.log("getAppication->Error: ", e.message);
    return new Promise((resolve) => {
      setTimeout(() => {
        const mockData = {
          //remove it once getapp working
          fullName: "Test Test",
          agreeToTerms: true,
          businessName: "Not working",
          /*           businessAdress: "",
          taxID: "46-3848431",
          monthlyRevenue: "50000",
          downpayment: "100000",
          equipmentLocation:
            "227 Payne Ridge Rd #3 Church Hill, Tennessee(TN), 37642",
          homeAddress: "",
          dateOfBirth: "03.23.1988", */
          confirmEquipmentLocation:
            "227 Payne Ridge Rd #3 Church Hill, Tennessee(TN), 37642",
          currentFunnel: 1,
          currentStep: 1,
        };
        resolve(mockData);
      }, 2000);
    });
  }
};

const getOffers = async (params: string) => {
  const applicationResponse = await getApplication(params);
  const email = applicationResponse.contactEmail
  const payload = {
    email: email,
  };
  try {
    const loadOffers = await fetch(`${Config.get("API_URL")}/api/loan/offers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic d2Vic2l0ZTpmb3Jt",
      },
      body: JSON.stringify(payload),
    });
    const response = await loadOffers.json();
    console.log("getOffers: ", response);
    return response;
  } catch (e: any) {
    console.log("getOffers->Error: ", e.message);
  }
};

const pathsToCheck = [
  "business-information",
  "finance-information",
  "number-of-owners",
  "sole-owner-information",
  "sole-owner-information-part2",
  "sign",
  "authorized-signer-question",
  "authorized-signer-information",
  "co-owner-invite",
  "owner-invite",
  "number-of-owners-thank-you",
  "ownership-percentage"
];

const pathsToCheckSubmitted = [
  "business-information",
  "finance-information",
  "number-of-owners",
  "sole-owner-information",
  "sole-owner-information-part2",
  "sign",
  "authorized-signer-question",
  "authorized-signer-information",
  "co-owner-invite",
  "owner-invite",
  "number-of-owners-thank-you",
  "ownership-percentage",
  "received",
  "optimize",
  "context-question-01",
  "context-question-02",
  "context-question-03",
  "context-question-04",
  "context-question-05",
  "context-question-06",
  "context-question-07",
  "context-question-08",
];

const pathsToCheckOffers = [
  "offers",
  "offer-selected",
  "offer-done",
  "documents",
  "documents/drivers-license",
  "documents/check",
  "documents/storage",
]

const loadApplication = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  const url = new URL(request.url);
  if (!params || !params.hash) throw new Error("Hash Required!");
  const applicationData = await getApplication(params.hash);
  applicationData.isMobile = isMobile();

  if (applicationData.Status) {
    if (
      pathsToCheck.some(path => url.pathname.includes(path)) &&
      (applicationData.Status[0] === "SIGN" || applicationData.Status[0] === "DOCUSIGN") &&
      (applicationData.requirements?.includes("3m_bank_statements") || applicationData.requirements?.includes("finance_statements"))
    ) {
      return redirect(`/${params.hash}/optimize`);
    }

    if (pathsToCheck.some(path => url.pathname.includes(path)) && (applicationData.Status[0] === "SIGN" || applicationData.Status[0] === "DOCUSIGN")) {
      return redirect(`/${params.hash}/received`);
    }

    if (pathsToCheck.some(path => url.pathname.includes(path)) && (applicationData.Status[0] === "REVIEW" || applicationData.Status[0] === "DOCUSIGN")) {
      return redirect(`/${params.hash}/received`);
    }

    if (pathsToCheckSubmitted.some(path => url.pathname.includes(path)) && (applicationData.Status[0] === "OPTIMIZE" || applicationData.Status[0] === "INTERRUPTED")) {
      return redirect(`/${params.hash}/in-review`);
    }

    if (pathsToCheckOffers.some(path => url.pathname.includes(path)) && (applicationData.Status[0] === "DOC_UPLOAD_OFFERS")) {
      return redirect(`/${params.hash}/in-review-final`);
    }
  }

  console.log("appData", funnelLogic(applicationData));
  return applicationData;
};

const loadOffers = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  const url = new URL(request.url);
  if (!params || !params.hash) throw new Error("Hash Required!");
  const offersData = await getOffers(params.hash);
  return offersData;
};

export const headerAtom = atom("");
export const headerStageAtom = atom(true);
export const docuStageAtom = atom(false);
export const narrowPageAtom = atom(false);
export const formStageView = atom(true);
export const popUpAtom = atom(false);
export const submitAtom = atom(false);

const router = createBrowserRouter([
  {
    path: "/:hash/business-information", // Required Business Information
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <BusinessInfo />
      </ScrollToTop>
    ),
    loader: loadApplication,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/:hash/finance-information", // Required Loan Information and Equipment showcase
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <LoanInfromation />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/number-of-owners", // Is requestor a sole owner of the company?
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <SoleOwner />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  /*   {
    path: "/:hash/multiple-owner-information", // User is owner of more than 20% of the business
    element: (
      <ScrollToTop>
        <MultipleOwners />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  }, */
  {
    path: "/:hash/ownership-percentage", // How many owners own more than 20% of business
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <MultipleOwnersInfo />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/sole-owner-information", // Required Address information
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <Address />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/authorized-signer-question", // Authorized Signer of business?
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <AuthSigner />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/authorized-signer-information", // Name and title of Authorized owner of the business
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <AuthInfo />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/sole-owner-information-part2", // Date of Birth & SSN number requested
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <Identification />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/sign", // Signing the Loan request
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <SignApp />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/received", // Finished Loan application
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <ThankYou />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/optimize", // Optimze the application with Uploads + Additional information
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <Optimize />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/bank-statements", // Requested bank statements upload
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <DocUpload />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/context", // Give more information about the business + reason for purchase
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <Context />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/in-review", // Succesfull document upload
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <DocFinished />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/co-owner-invite", // Fill out the basic information for co-owners
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <CoOwnerInvite />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/owner-invite", // Fill out the basic information for DocSign
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <OwnerInvite />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/upload", // For missing documents sent to MAZO
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <DocReUpload />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/offers", // Selecting the offer
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <OfferSelection />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadOffers,
  },
  {
    path: "/:hash/offer-selected", // Succesfully Selected offer
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <OfferSelected />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadOffers,
  },
  {
    path: "/:hash/offer-done", // Offer succesfully selected
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <OfferReview />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadOffers,
  },
  {
    path: "/:hash/documents", // Optimize menu for Offer
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <DocUploadForMazo />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/documents/drivers-license", // Upload Driver's License
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <DriverLUpload />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/documents/check", // Upload Blank Check
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <CheckUpload />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/documents/storage", // Thank you page for docs uploaded
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <StorageLocation />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/in-review-final", // Thank you page for docs uploaded
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <DocUploadForMazoThank />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/all-done-thank-you", // Final Thank you page with contract details
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <FinalThankYou />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/number-of-owners-thank-you", // Off ramp page if tere are multiple owners
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <ThankYouOffRamp />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/:hash/leave-application", // Leave the app securely page
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <LeftPage />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/:hash/context-question-01", // StartUp Questionarre
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <StartUpQuestion1 />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/context-question-02", // StartUp Questionarre
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <StartUpQuestion2 />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/context-question-03", // StartUp Questionarre
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <StartUpQuestion3 />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/context-question-04", // StartUp Questionarre
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <StartUpQuestion4 />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/context-question-05", // StartUp Questionarre
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <StartUpQuestion5 />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/context-question-06", // StartUp Questionarre
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <StartUpQuestion6 />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/context-question-07", // StartUp Questionarre
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <StartUpQuestion7 />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/:hash/context-question-08", // StartUp Questionarre
    element: (
      <ScrollToTop>
        <PostHogPageView />
        <StartUpQuestion8 />
      </ScrollToTop>
    ),
    errorElement: <ErrorBoundary />,
    loader: loadApplication,
  },
  {
    path: "/",
    element: <div>Finance Application Public View</div>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/credit-application",
    element: (<ManualCreditEntry />),
    errorElement: <ErrorBoundary />,
  }
]);

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  return <div>Error!</div>;
}

function isMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
}

function funnelLogic(applicationData: any) {
  const funnels = [
    /* 0 - FUNNEL 1 */ [
      "business-information",
      "finance-information",
      "authorized-signer-question",
      "authorized-signer-information",
      "sign",
      "received",
      "optimize",
      "in-review",
    ],
    /* 1 - FUNNEL 2 SINGLE */ [
      "business-information",
      "finance-information",
      "number-of-owners",
      "sole-owner-information",
      "sole-owner-information-part2",
      "sign",
      "received",
      "optimize",
      "in-review",
    ],
    /* 2 - FUNNEL 3 SINGLE */ [
      "business-information",
      "finance-information",
      "number-of-owners",
      "sole-owner-information",
      "sole-owner-information-part2",
      "sign",
      "received",
      "optimize",
      "context-question-01",
      "context-question-02",
      "context-question-03",
      "context-question-04",
      "context-question-05",
      "context-question-06",
      "context-question-07",
      "context-question-08",
      "in-review",
    ],
    /* 3 - FUNNEL 2 MULTI */ [
      "business-information",
      "finance-information",
      "number-of-owners",
      "ownership-percentage",
      "sole-owner-information",
      "sole-owner-information-part2",
      "co-owner-invite",
      "number-of-owners-thank-you",
      "received",
      "optimize",
      "in-review",
    ],
    /* 4 - FUNNEL 3 MULTI */ [
      "business-information",
      "finance-information",
      "number-of-owners",
      "ownership-percentage",
      "sole-owner-information",
      "sole-owner-information-part2",
      "co-owner-invite",
      "number-of-owners-thank-you",
      "received",
      "optimize",
      "context-question-01",
      "context-question-02",
      "context-question-03",
      "context-question-04",
      "context-question-05",
      "context-question-06",
      "context-question-07",
      "context-question-08",
      "in-review",
    ],
    /* 5 - FUNNEL 1 DOCSIGN */ [
      "business-information",
      "finance-information",
      "number-of-owners",
      "owner-invite",
      "number-of-owners-thank-you",
      "received",
      "optimize",
      "in-review",
    ],
  ];
  let currentPath =
    funnels[applicationData.currentFunnel][applicationData.currentStep - 1];
  return currentPath
/*   {
    path: currentPath,
    step: applicationData.currentStep,

  }; */
}

const NEXT_PUBLIC_POSTHOG_KEY = "phc_h4XYxlk7oGdjyk4QEIgQCzxjmA4SRuCQsaWA0cSQOOZ"
const NEXT_PUBLIC_POSTHOG_HOST = "https://us.i.posthog.com"

function App() {
  const [formHeader] = useAtom(headerAtom);
  const [formDocuBar] = useAtom(docuStageAtom);
  const [formOfferView] = useAtom(formStageView);
  const [formNarrow] = useAtom(narrowPageAtom);
  const [showSubmitModal, setShowSubmitModal] = useAtom(submitAtom);
  const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
  const PopUpToggler = () => {
    setShowPopUp(!showPopUp);
  };

  if (typeof window !== 'undefined') {
    posthog.init(NEXT_PUBLIC_POSTHOG_KEY!, {
      api_host: NEXT_PUBLIC_POSTHOG_HOST,
      person_profiles: 'identified_only',
      autocapture: false,
      capture_pageview: false,
      capture_pageleave: true,
    });
  }

  return (
    <>
      <PostHogBehaviorTracker>
        {showSubmitModal && <SubmitModal />}
        {showPopUp && <PopUp toggle={PopUpToggler} />}
        <Header />
        <div
          className={`bg-neutral-100 mobile-page-padding ${
            formDocuBar ? `pb-4` : `py-20`
          } ${formNarrow ? `pb-[26rem]` : `pb-4`}`}
        >
          <div className="flex justify-center">
            {formDocuBar ? (
              <>
                <DocumentsProgressBar />
              </>
            ) : (
              <></>
            )}
          </div>
          {formOfferView ? (
            <>
              <div className="w-full max-w-screen-sm mx-auto block min-h-[100px] bg-white auto-cols-[1fr] gap-x-10 gap-y-10 grid-cols-[1fr] grid-rows-[auto] overflow-visible border border-neutral-200 rounded-lg border-solid mobile-cont mobile-form-pad">
                <div className="text-center">
                  <div className="text-[#171718] text-2xl leading-[1.3] font-bold text-left p-10 mobile-form-pad">
                    {formHeader}
                  </div>
                </div>
                <div className="max-w-none mt-0 mx-auto">
                  <div className="flex w-auto min-w-[auto] flex-col auto-cols-[1fr] gap-x-6 grid-cols-[1fr] grid-rows-[auto] bg-white pb-10 px-10 rounded-lg mobile-form-pad">
                    <RouterProvider
                      router={router}
                      fallbackElement={<LoadingScreen />}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-start auto-cols-[1fr] gap-x-4 grid-cols-[1fr_1fr] grid-rows-[auto_auto] mt-10 mb-10 mobile-bottom-margin h-12">
                <img src={docusign} loading="lazy" alt="docusign logo" className="w-[40rem]"/>
              </div>{" "}
            </>
          ) : (
            <>
              <div className="flex w-auto justify-center">
                <RouterProvider
                  router={router}
                  fallbackElement={<LoadingScreen />}
                />
              </div>
            </>
          )}
        </div>
        <Footer />
      </PostHogBehaviorTracker>
    </>
  );
}

export default App;
