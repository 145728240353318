import "../../../styles/style.css";
import mp from "../../../assets/equipment-placeholder.png";

function EquipmentCardGrey(props: any) {
  return (
    <>
        <div className="relative flex flex-row mb-6">
            <div>
                <img
                src={props.url ? props.url : mp}
                className="max-w-[7rem] pr-5"
                alt="selected machine"
                />
            </div>
            <div className="flex flex-col">
            <div className="text-[#727373] font-normal text-[0.875_rem] leading-[21px];">
                {props.brand}
            </div>
            <div className="text-[#171718] font-semibold pl-0">
                {props.name}
            </div>
            </div>
        </div>
    </>
  );
}

export default EquipmentCardGrey;

