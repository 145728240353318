import mp from "../../../assets/equipment-placeholder.png";
import completed from "../../../assets/completed.png";
import "../../../styles/style.css";

function EquipmentCard(props: any) {

  const price = parseFloat(props.price.replace(/[$,]/g, ""));

  return (
    <>
      <div key={props.key} className="relative flex flex-col mb-6">
        <div className="grid flex-row w-full grid-cols-[1fr_3fr_0.5fr] border items-center border-neutral-300 rounded flex-none order-1 self-stretch grow-0 border-solid p-6 mobile-eq-container">
          <img
            src={props.url ? props.url : mp}
            className="max-h-[4rem] pr-5 rounded-sm"
            alt="selected machine"
          />
          <div className="">
            <div className="text-[#727373] font-normal text-[0.875_rem] leading-[21px];">
              {props.brand}
            </div>
            <div className="text-[#171718] font-semibold pl-0">
              {props.name}
            </div>
            <div className="text-[#171718] font-semibold pl-0">
              ${price.toLocaleString()} (excl. taxes)
            </div>
          </div>
          <img
            src={completed}
            className="mobile-completed max-w-[3rem] rounded-sm"
            alt="completed icon"
          />
        </div>
      </div>
    </>
  );
}

export default EquipmentCard;
