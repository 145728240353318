import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from "posthog-js/react";

const NEXT_PUBLIC_POSTHOG_KEY = "phc_h4XYxlk7oGdjyk4QEIgQCzxjmA4SRuCQsaWA0cSQOOZ"
const NEXT_PUBLIC_POSTHOG_HOST = "https://us.i.posthog.com"

type BehavioralAnalyticsProps = {
    children: ReactNode;
};

type PostHogContextType = {
    identifyUser: (email: string, properties?: Record<string, any>) => void;
    trackEvent: (event: string, properties?: Record<string, any>) => void;
};

const PostHogContext = createContext<PostHogContextType>({
    trackEvent: () => { },
    identifyUser: () => { },
});

export const usePostHogAnalytics = () => {
    const context = useContext(PostHogContext);
    if (!context) {
        throw new Error('usePostHogAnalytics must be used within a PostHogBehaviorTracker');
    }
    return context;
};

export function PostHogBehaviorTracker({ children }: BehavioralAnalyticsProps): JSX.Element {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined' && !isInitialized) {
            posthog.init(NEXT_PUBLIC_POSTHOG_KEY!, {
                api_host: NEXT_PUBLIC_POSTHOG_HOST,
                person_profiles: 'identified_only',
                capture_pageview: false,
                capture_pageleave: true,
            });
            
            const storedDistinctId = localStorage.getItem('ph_distinct_id');
            if (storedDistinctId) {
                posthog.identify(storedDistinctId);
            } else {
                const currentDistinctId = posthog.get_distinct_id();
                localStorage.setItem('ph_distinct_id', currentDistinctId);
            }
      
            setIsInitialized(true);
        }
    }, [isInitialized]);

    const identifyUser = (email: string, properties?: Record<string, any>) => {
        if (isInitialized) {
            posthog.identify(email, properties);
            localStorage.setItem('ph_distinct_id', email);
        }
    };

    const trackEvent = (event: string, properties?: Record<string, any>) => {
        if (isInitialized) {
            posthog.capture(event, properties);
        }
    };

    return (
        <PostHogContext.Provider value={{ identifyUser, trackEvent }}>
            <PostHogProvider client={posthog}>
                {children}
            </PostHogProvider>
        </PostHogContext.Provider>
    );
}