import "../styles/App.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom } from 'jotai';
import { docuStageAtom, headerAtom, headerStageAtom, narrowPageAtom, submitAtom } from "../App";
import check from "../assets/icon-clock.svg";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import { bbuttonAtom } from "../components/Pages/Layout/backbutton";
import { useEffect } from "react";


function DocFinished(props: any) {

    const params = useParams();
    const navigate = useNavigate();

    const onSubmitHandler = async (values: any) => {
        navigate(`/${params.hash}/leave-application`)    
    };

    const [formHeader, setFormHeader] = useAtom(headerAtom)  
    const [progressHeader, setProgressHeader] = useAtom(headerStageAtom) 
    const [formButtonShow, setFormButtonShow] = useAtom(bbuttonAtom);   
    const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom)   
    const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom)
    const [DocProgress, setDocProgress] = useAtom(DocProgressAtom)  
    const [showSubmitModal, setShowSubmitModal] = useAtom(submitAtom)

    useEffect(() => {
        setFormHeader('Thank you! Your application is in review!')
        setProgressHeader(false)
        setFormButtonShow(`hideall`);
        setformDocuBar(true)
        setFormNarrow(false)
        setDocProgress("step2")
        setShowSubmitModal(false)
    })

    return (
        <>
            <img className="w-40 fill-[#40C057] stroke-[#40C057] p-10 justify-self-center self-center bg-[#EBFBEE] rounded-full border-solid border-[#40C057]" src={check} alt="checkmark"></img>
            <div className="mt-6">
                <div className="font-normal text-base text-[#525351]">We’ll notify you in 24-48 hours by email and on this page when we are ready. Have questions? Call us at <u className="text-[#228BE6]" >+1-800-329-3375</u></div>
            </div>
            <button className="btn btn-primary mt-12" onClick={onSubmitHandler}>Leave This Page Securely</button>
        </>
    )
}

export default DocFinished;