const Config: any = {
  ENV: "production",
  development: {
    API_URL: "https://3f14-179-84-97-201.ngrok-free.app"
  },
  production: {
    API_URL: "https://mp-loan-application.vercel.app"
  }
}

const get = (key: string) => {
  return Config[Config.ENV][key as string]
}

export default {
  get
};