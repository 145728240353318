import "../../../styles/style.css";

const LoadingButton = (props: any) => {

return (
<button type="submit" className="btn btn-loading">
<svg className="animate-spin" xmlns="http://www.w3.org/2000/svg" 
  width="24" 
  height="24" 
  viewBox="0 0 24 24" 
  fill="none" 
  stroke="#171718" 
  /* className="lucide lucide-loader-2" */>
  <path d="M21 12a9 9 0 1 1-6.219-8.56"></path>
  </svg>
  <div>Loading...</div>
</button>
)
}

export default LoadingButton;