import React from "react";

import { useFormContext, Controller } from "react-hook-form";
import { CurrencyFieldAttributes } from "../../models/FieldAttributes";
import { NumericFormat } from "react-number-format";

const CurrencyField: React.FC<CurrencyFieldAttributes> = ({
  label,
  name,
  type,
  defaultValue,
  validation,
}) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  // if (errors[name]) console.log("CurerncyField->errors: ", errors[name]);
  return (
    <div className="relative w-full mb-2">
      <label htmlFor={name} {...(validation.required ? { 'data-required': 'true'} : {})} className="leading-6 pb-2 text-[#171718] font-semibold pl-0">
        {label}
      </label>

      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue as string}
        render={({ field: { onChange, onBlur, name, value } }) => (
          <NumericFormat
            // defaultValue={defaultValue as string}
            {...register(name, validation)}
            id={name}
            prefix={"$"}
            decimalScale={0}
            thousandSeparator=","
            value={value as string}
            fixedDecimalScale
            onBlur={onBlur}
            onChange={onChange}
            className={` rounded w-full shadow-[inset_1px_1px_3px_0_rgba(0,0,0,0.06)] min-h-[3rem] mb-0 px-4 py-2 border-solid border-2 ${errors[name]?.type === "required" ? ('border-[#FA5252] bg-[#FFF5F5]') : ('border-neutral-300')}`}
          />
        )}
      />
      {errors[name] ? (
        <>
          {errors[name]?.type === "required" ? (
            <>
              <span className="absolute left-0 top-full min-h-[0.25rem] text-[#FA5252] text-sm">
                Field "{label}" is required.
              </span>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CurrencyField;
