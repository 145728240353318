import React from "react";

import { useFormContext } from "react-hook-form";
import { EmailFieldAttributes } from "../../models/FieldAttributes";

const EmailField: React.FC<EmailFieldAttributes> = ({
  label,
  name,
  type,
  defaultValue,
  validation,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // if (errors[name]) console.log("EmailField->errors: ", errors[name]);
  return (
    <div className="relative w-full mb-2">
      <label htmlFor={name} {...(validation.required ? { 'data-required': 'true'} : {})} className="leading-6 pb-2 text-[#171718] font-semibold pl-0">
        {label}
      </label>
      <div>
        <input
          className={` rounded w-full shadow-[inset_1px_1px_3px_0_rgba(0,0,0,0.06)] min-h-[3rem] mb-0 px-4 py-2 border-solid border-2 ${errors[name]?.type === "required" ? ('border-[#FA5252] bg-[#FFF5F5]') : ('border-neutral-300')}`}
          defaultValue={defaultValue}
          {...register(name, {
            ...validation,
            pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
          })}
          id={name}
          type="text"
        />
      </div>
      {errors[name] ? (
        <>
          {errors[name]?.type === "required" ? (
            <>
              <span className="absolute left-0 top-full min-h-[0.25rem] text-[#FA5252] text-sm">
                Field "{label}" is required.
              </span>
            </>
          ) : (
            <></>
          )}
          {errors[name]?.type === "pattern" ? (
            <>
              <span className="absolute left-0 top-full min-h-[0.25rem] text-[#FA5252] text-sm">
                Field "{label}" must be an email.
              </span>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EmailField;
