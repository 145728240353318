import "../styles/App.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom } from 'jotai';
import { docuStageAtom, headerAtom, headerStageAtom, popUpAtom, submitAtom } from "../App";
import check from "../assets/check.svg";
import { DocProgressAtom } from "../components/Pages/Layout/documentsbar";
import ButtonContainer from "../components/Pages/Layout/buttoncontainer";


function DocUploadForMazoThank(props: any) {

    const params = useParams();
    const navigate = useNavigate();  

    const saveHandler = async (values: any) => {
        PopUpToggler();
      };
    
      const navigateHandler = async (values: any) => {
        navigate(`/${params.hash}/`);
      };

    const [formHeader, setFormHeader] = useAtom(headerAtom)
        setFormHeader('Thank you! Your documents are in review!')
    const [progressHeader, setProgressHeader] = useAtom(headerStageAtom)
        setProgressHeader(false)
    const [formDocuBar, setformDocuBar] = useAtom(docuStageAtom)
        setformDocuBar(true)
    const [DocProgress, setDocProgress] = useAtom(DocProgressAtom);
        setDocProgress("step4");
    const [showPopUp, setShowPopUp] = useAtom(popUpAtom);
    const PopUpToggler = () => {setShowPopUp(!showPopUp); };
    const [showSubmitModal, setShowSubmitModal] = useAtom(submitAtom)
    setShowSubmitModal(false)
    

    return (
        <>
            <img className="flex w-40 p-10 justify-self-center self-center bg-[#EBFBEE] rounded-full border-solid border-[#40C057]" src={check} alt="checkmark"></img>
            <div className="font-normal text-base text-[#525351] mt-10">We’ll notify you in 24-48 hours by email or phone when we are ready or if there are additional questions. Have questions? Call us at <u className="text-[#228BE6]" >+1-800-329-3375</u></div>
            <a href="https://www.machinerypartner.com/">
                <button className="btn btn-primary mt-16 w-full">
                    Leave This Page Securely
                </button>
            </a>
        </>
    )
}

export default DocUploadForMazoThank;