import { useAtom } from "jotai";
import { narrowPageAtom } from "../../../App";


function LoadingScreen(props: any) {

    const [formNarrow, setFormNarrow] = useAtom(narrowPageAtom)
    setFormNarrow(true)

    return (
        <>  
            <div className="flex align-middle mb-10">
                <div className="btn btn-loading">
                    <svg className="animate-spin" xmlns="http://www.w3.org/2000/svg" 
                        width="24" 
                        height="24" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="#171718" 
                        >
                    <path d="M21 12a9 9 0 1 1-6.219-8.56"></path>
                    </svg>
                Finance Flow Application Loading...
                </div>
            </div> 
        </>
    )
}

export default LoadingScreen;