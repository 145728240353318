import check from "../../../assets/checkbox-icon.svg";
import "../../../styles/style.css";
import { atom, useAtom } from "jotai";

export const DocProgressAtom = atom("step1");

function DocumentsProgressBar() {
  const [DocProgress] = useAtom(DocProgressAtom);
  let currentProgress = Number(DocProgress.split("step")[1]);

  let renderStep = (stepNumber : any , label : any) => {
    const isCompleted = currentProgress > stepNumber;
    const isActual = currentProgress === stepNumber;
    const content = isCompleted ? (
      <img src={check} alt="checkmark" />
    ) : (
      stepNumber
    );

    const classNames = `flex relative ${isCompleted ? 'docubar-nr' : (isActual ? 'docubar-nrCurrrent' : 'docubar-nr-grey')}`;

    return (
      <div className={classNames}>
        {content}
        <div className="h-8 absolute top-8 docubar-text">{label}</div>
      </div>
    );
  };
  return (
    <div className="flex flex-col w-full bg-transparent max-w-screen-sm pt-16 pb-20 px-8">
      <div className="flex flex-row">
        {renderStep(1, 'Application')}
        <div className={`${currentProgress >= 2 ? "docubar-conn" : "docubar-conn-grey"}`} />
        {renderStep(2, 'Optimize')}
        <div className={`${currentProgress >= 3 ? "docubar-conn" : "docubar-conn-grey"}`} />
        {renderStep(3, 'Offers')}
        <div className={`${currentProgress >= 4 ? "docubar-conn" : "docubar-conn-grey"}`} />
        {renderStep(4, 'Contract')}
      </div>
    </div>
  );
}

export default DocumentsProgressBar;
